const currentUrl = window.location.href

export const translation = {
  action: 'Действие',
  add: 'Добавить',
  'add-retranslators': 'Добавить ретранслятор',
  'add-terminal': 'Добавление терминалов',
  addCompany: 'Добавить компанию',
  'addCompanyTransalter.addUser': 'ДОБАВИТЬ ПОЛЬЗОВАТЕЛЯ',
  'addCompanyTransalter.cancel': 'ОТМЕНИТЬ',
  'addCompanyTransalter.description': 'Добавление нескольких терминалов при переносе на новую строчку.',
  'addCompanyTransalter.error.enterEmail': 'Введите корректный адрес электронной почты',
  'addCompanyTransalter.error.enterName': 'Введите имя пользователя',
  'addCompanyTransalter.error.enterPassword': 'Введите пароль',
  'addCompanyTransalter.error.name': 'Заполните поле "Имя компании"',
  'addCompanyTransalter.error.password': 'Пароли не совпадают',
  'addCompanyTransalter.error.repeatPassword': 'Пароли должны совпадать',
  'addCompanyTransalter.error.time': 'Заполните поле "Часовой пояс"',
  'addCompanyTransalter.password': 'Пароль:',
  'addCompanyTransalter.repeatPassword': 'Подтвердите пароль:',
  'addCompanyTransalter.save': 'Сохранить',
  'addCompanyTransalter.selectCompany': 'Переключиться в выбранную компанию',
  'addLocatorTranslate.selectDateFrom': 'Выберите дату начала',
  'addLocatorTranslate.selectDateTo': 'Выберите дату завершения',
  'addLocatorTranslate.selectObject': 'Выберите объект',
  'add_parametrs.accepted': 'Принято',
  'add_parametrs.add_parametrs': 'Добавление параметров',
  'add_parametrs.good': 'Хорошо',
  'add_parametrs.no_accepted': 'Не принято',
  'add_parametrs.parametrs_available': 'Доступно параметров',
  'add_parametrs.parametrs_time': 'Успешно добавлены следующие параметры:',
  'add_parametrs.protocol': 'Протокол',
  'add_parametrs.send_parametrs': 'Отправить параметры на добавление',
  'add_parametrs.support': 'Обратитесь в поддержку для добавления следующих параметров (так как их нельзя добавить)',
  'admin-panel': 'Админ. панель',
  'admin-panel.company': 'Компания',
  'admin-panel.download-finance': 'Скачать отчет',
  'admin-panel.finance': 'Финансовый отчет',
  'admin-panel.is-company': 'Введите компанию!',
  'admin-panel.module': 'Модуль',
  'admin-panel.period': 'Период',
  'admin-panel.show': 'Показать',
  'admin-panel.timezone': 'Часовой пояс',
  'admin-panel.title': 'Админ-панель',
  'adminPanelTranlsater.acceptDeleteTemplate': 'Вы уверены, что хотите удалить Шаблон ?',
  'adminPanelTranlsater.addCompany': 'Добавить компанию',
  'adminPanelTranlsater.cancel': 'Отменить',
  'adminPanelTranlsater.cashType.card': 'с карты',
  'adminPanelTranlsater.cashType.deposit': 'ручной',
  'adminPanelTranlsater.cashType.trial': 'пробная версия',
  'adminPanelTranlsater.cashType.withdrawal': 'оплата тарифа',
  'adminPanelTranlsater.command': 'Команда',
  'adminPanelTranlsater.company': 'Компания',
  'adminPanelTranlsater.copyObject': 'Скопировать объект',
  'adminPanelTranlsater.copyTemplate': 'Скопировать Шаблон',
  'adminPanelTranlsater.date': 'Дата',
  'adminPanelTranlsater.delete': 'Удалить',
  'adminPanelTranlsater.enterEmail': 'введите электронную почту',
  'adminPanelTranlsater.grade': 'Оценка',
  'adminPanelTranlsater.importKML': 'Импорт из KML',
  'adminPanelTranlsater.importWialon': 'Импорт из Wialon',
  'adminPanelTranlsater.invite': 'Пригласить',
  'adminPanelTranlsater.message': 'Cообщение',
  'adminPanelTranlsater.name': 'Имя',
  'adminPanelTranlsater.notData': 'Данные отсутствуют',
  'adminPanelTranlsater.numberType': '№ Тип',
  'adminPanelTranlsater.object': 'Объект',
  'adminPanelTranlsater.period': 'Период',
  'adminPanelTranlsater.phone': 'Телефон',
  'adminPanelTranlsater.protocol': 'Протокол',
  'adminPanelTranlsater.recoveryObject': 'Восстановить объект',
  'adminPanelTranlsater.total': 'Сумма',
  'adminPanelTranlsater.type': 'Тип',
  'adminPanelTranlsater.typeTerminal': 'Тип терминала',
  'adminPanelTranlsater.user': 'Пользователь',
  'adminPanelTranlsater.wrongEmail': 'Неверная электронная почта',
  'admintariff.accept': 'Подтвердить',
  'admintariff.cancel': 'Отменить',
  'admintariff.changetitle': 'Изменение тарифа',
  'admintariff.company': 'Компания',
  'admintariff.date': 'Дата следующего платежа',
  'admintariff.days': 'дней',
  'admintariff.for': 'за',
  'admintariff.historyobjects': 'объектов',
  'admintariff.newtariff': 'Новый тариф',
  'admintariff.object': 'объекта',
  'admintariff.objects': 'объектов каждые',
  'admintariff.objectsnumber': 'Количество объектов',
  'admintariff.oldtariff': 'Старый тариф',
  'admintariff.paymentdate': 'Дата платежа',
  'admintariff.period': 'Период оплаты',
  'admintariff.price': 'Стоимость одного объекта',
  'admintariff.refill': 'Пополнить',
  'admintariff.tariff': 'Тариф',
  already_deleted_elements: 'элемента были удалены',
  'annotation.additional': 'Дополнительно:',
  'annotation.data_date': 'Дата получения данных:',
  'annotation.gps_data': 'GPS-Данные:',
  'annotation.last_params': 'Последние данные:',
  'annotation.reports': 'Отчеты:',
  'annotation.sensors': 'Датчики:',
  'annotationCameraTranslater.grid': 'Сетка',
  'annotationCameraTranslater.object': 'Обьект:',
  'annotationCameraTranslater.ribbon': 'Лента',
  'annotationCameraTranslater.see': 'Смотреть',
  'annotationCameraTranslater.video': 'Видео',
  'annotationCameraTranslater.videoRegister': 'Видео-регистратор:',
  attention: 'Внимание',
  author: 'Автор',
  back: 'назад',
  blocking: 'Блокировка',
  'company.blocked': 'Компания заблокирована',
  'company.balance': 'Баланс:',
  'company.history_pay': 'История списаний',
  'company.go.pay': 'Оплатить',
  'company.blocked.text1': 'Доступ к компании заблокирован. Вы можете оплатить задолженность в размере',
  'company.blocked.text2': 'для возобновления доступа.',
  'company.blocked.text3': 'Доступ к компании заблокирован. Обратитесь к интегратору для возобновления доступа.',
  'btn.cancel': 'Отмена',
  'btn.create': 'Создать',
  'btn.delete': 'Удалить',
  'btn.editlist': 'РЕДАКТИРОВАТЬ СПИСОК',
  'btn.finisheditlist': 'ЗАКОНЧИТЬ РЕДАКТИРОВАНИЕ',
  'btn.save': 'Сохранить',
  'btn.select': 'Выбрать',
  'btn.update': 'Обновить',
  cancel: 'Отменить',
  'catalog.user': 'Справочник пользователя',
  change: 'Изменить',
  close: 'Закрыть',
  code: 'Код',
  'come.back': 'Вернуться',
  'command-terminal': 'Команда',
  companies: 'Компании',
  'companiesListTranslater.cancel': 'Отменить',
  'companiesListTranslater.copiedObject': 'скопировать объект.',
  'companiesListTranslater.copyObject': 'Копирование объекта',
  'companiesListTranslater.save': 'Сохранить',
  'companiesListTranslater.selectCompany': 'Выберите компанию в которую хотите',
  'company.accounting_type': 'Тип учета:',
  'company.add_card_cost_note': 'Для добавления карты автоплатежа, нам потребуется списать у вас . При оплате укажите реквизиты карты.',
  'company.autopayment': 'Автоплатёж',
  'company.autopayment_on': 'Включить автоплатёж',
  'company.autopayment_unavailable_with_mir': 'Автоплатёж временно недоступен с картами МИР',
  'company.by_number_objects': 'По числу объектов',
  'company.by_number_objects_sent_data': 'По числу объектов присылавших данные',
  'company.cancel': 'Отменить',
  'company.card_add_for_autopayments': 'Добавление карты для автоплатежа',
  'company.change_card_autopayment': 'Смена карты для автоплатежа',
  'company.change_card_cost_note': 'Для изменения карты автоплатежа, нам потребуется списать у вас 1 рубль. При оплате укажите реквизиты новой карты.',
  'company.choose_payment_system': 'Выберите платёжную систему',
  'company.continue': 'Продолжить',
  'company.cost_one_object': 'Стоимость 1 объекта:',
  'company.date-format': 'Формат даты',
  'company.download_offer': 'Скачать оферту',
  'company.exitTime': 'Выход через n минут бездействия',
  'company.integrator': 'Дилер',
  'company.last_date_widget': 'Время последней даты в виджете',
  'company.last_date_widget_note': 'Укажите какой формат времени будет применяться при выборе последней даты в интервале дат (в виджете выбора дат).',
  'company.max_number_objects': 'Макс. кол-во объектов:',
  'company.minimum_payment_amount': 'Минимальный размер платежа 1 руб.',
  'company.name': 'Имя',
  'company.no': 'Нет',
  'company.passwordLabel': 'Режим повышенной безопасности',
  'company.passwordLabelText1': 'Для повышения безопасности, при активации этого режима новым пользователям, потребуется создавать надежные пароли. Такой пароль должен содержать не менее 15 символов, включать буквы верхнего и нижнего регистра, цифры и специальные символы.',
  'company.passwordLabelText2': 'При смене пароля пользователю, необходимо войти в систему с использованием пароля от администратора, а затем установить собственный пароль для учетной записи. Таким образом, администратору не будет известен пароль от аккаунта пользователя.',
  'company.payment_period': 'Период оплаты:',
  'company.previous_period_amount': 'Сумма за прошлый период',
  'company.retransmit': 'Ретранслировать в ЕФИС',
  'company.select_payment_system': 'Выберите платёжную систему:',
  'company.start_current_period': 'Начало текущего периода:',
  'company.temporarily_unavailable': 'Временно не доступно',
  'company.time-format': 'Формат времени',
  'company.timeZone': 'Часовой пояс',
  'company.tooltip': '0 или пустое поле - не будет выхода по бездействию',
  'company.use_card_for_autopayments': 'Использовать карту для автоплатежа',
  'company.visa_or_mastercard': 'Visa или Mastercard',
  company_system_mir: 'Система МИР',
  confirm: 'Подтвердить',
  'content.object_last_state_move': 'Последнее состояние: объект в движении',
  'content.object_last_state_stay': 'Последнее состояние: объект стоит',
  'content.object_move': 'Объект в движении',
  'content.object_stay': 'Объект стоит',
  copy: 'IMEI скопирован!',
  'copyReportTemplateTranslater.cancel': 'Отменить',
  'copyReportTemplateTranslater.copy': 'скопировать шаблон.',
  'copyReportTemplateTranslater.save': 'Сохранить',
  'copyReportTemplateTranslater.selectCompany': 'Выберите компанию в которую хотите',
  'copyReportTemplateTranslater.title': 'Копирование шаблона',
  create: 'Создать',
  'createPayment.amount': 'Сумма платежа',
  'createPayment.company': 'Компания',
  'createPayment.createPayment': 'Создать платёж',
  'createPayment.error.createPayment': 'Платеж создан',
  'createPayment.error.enterAmount': 'Заполните поле "Сумма платежа"',
  'createPayment.error.enterCompany': 'Заполните поле "Kомпания"',
  'createPayment.error.error': 'Ошибка',
  'createPayment.numberPayment': '№ платежа',
  'createPayment.title': 'Создать платёж',
  'createPayment.upBalance': 'Данная операция увеличит баланс выбранной компании',
  'dealerTranslater.INN': 'ИНН',
  'dealerTranslater.address': 'Адрес',
  'dealerTranslater.back': 'Назад',
  'dealerTranslater.confirmApp': 'приложение',
  'dealerTranslater.confirmContacts': 'Контакты:',
  'dealerTranslater.confirmDescAdmin': 'Описание Админ панели',
  'dealerTranslater.confirmDescBill': 'Описание тарификации',
  'dealerTranslater.confirmDescUi': 'Описание интерфейса',
  'dealerTranslater.confirmDownload': 'Загрузить',
  'dealerTranslater.confirmFastStart': 'Быстрый старт',
  'dealerTranslater.confirmGoToApp': 'Перейти в приложение',
  'dealerTranslater.confirmHeader': 'Регистрация нового интегратора завершена',
  'dealerTranslater.confirmMaterials': 'Дополнительные материалы:',
  'dealerTranslater.confirmMsg': 'В ближайшее время вы получите email письмо с ссылкой на телеграм чат поддержки, в чате вам ответят на все вопросы и помогут при миграции с другой платформы.',
  'dealerTranslater.email': 'Email',
  'dealerTranslater.enter': 'Укажите',
  'dealerTranslater.enterCodeFromImage': 'Для завершения регистрации введите код с картинки:',
  'dealerTranslater.enterEmail': 'Введите ваш email',
  'dealerTranslater.enterINN': 'Введите ваш ИНН',
  'dealerTranslater.enterNameCompany': 'Введите название компании',
  'dealerTranslater.enterPassword': 'Введите пароль',
  'dealerTranslater.enterPhone': 'Введите ваш номер телефона',
  'dealerTranslater.enterSurname': 'Введите ваш ФИО',
  'dealerTranslater.goRegister': 'Зарегистрироваться',
  'dealerTranslater.nameCompany': 'Название компании',
  'dealerTranslater.next': 'Далее',
  'dealerTranslater.password': 'Пароль',
  'dealerTranslater.register': 'Регистрация нового дилера',
  'dealerTranslater.registerNewDealer': 'Регистрация нового дилера',
  'dealerTranslater.rewritePassword': 'Подтвердите пароль*',
  'dealerTranslater.services': 'Описание услуг',
  'dealerTranslater.surname': 'ФИО',
  'dealerTranslater.tariff_description': 'Описание тарифа',
  'dealerTranslater.typeOrganization': 'Тип организации',
  delete: 'Удалить',
  'demoTourTranslater.interface': 'Видеообзор интерфейса',
  'demoTourTranslater.link': 'Связь с разработчиками',
  'demoTourTranslater.modules': 'Модули',
  'demoTourTranslater.next': 'Далее',
  'demoTourTranslater.search': 'Поиск, уведомления и настройки',
  'demoTourTranslater.text': 'Мы стремимся предоставить пользователям четкое и полное представление об объектах, поэтому в нашем приложении большое внимание уделяется взаимодействию с картой, так как она является основным инструментом визуализации.',
  'demoTourTranslater.title': 'Ваш основной инструмент — это карта',
  'demoTourTranslater.video': 'Видеообзор интерфейса',
  'demoTourTranslater.watchVideoYouTube': 'Смотреть ознакомительное видео на YouTube',
  'demoTourTranslater.workMap': 'Работа с картой',
  description: 'Описание',
  details: 'Дополнительно',
  'edit-retranslators': 'Редактировать ретранслятор',
  'edit-terminal': 'Редактирование терминала',
  'editTarifTranslater.amount': 'Сумма:',
  'editTarifTranslater.amountPer1Object': 'Стоимость за 1 объект',
  'editTarifTranslater.changePeriod': 'Вы изменили период оплаты, поэтому дата следующего платежа изменилась с',
  'editTarifTranslater.dateNextPayment': 'Дата следующего платежа не может находится в прошлом. Измените дату начала платежа или увеличьте период оплаты.',
  'editTarifTranslater.dateStart': 'Дата начала тарифа:',
  'editTarifTranslater.info': 'Сумма платежа за период оплаты формируется по формуле: Макс. количество объектов, помноженное на стоимость 1 объекта.',
  'editTarifTranslater.info_2': 'Сумма платежа за период оплаты формируется по формуле: Кол-во объектов приславших данные за период, помноженное на стоимость 1 объекта',
  'editTarifTranslater.max': 'Макс. количество объектов',
  'editTarifTranslater.nextPayment': 'Следующий платёж по тарифу:',
  'editTarifTranslater.periodStart': 'Начало действия периода',
  'editTarifTranslater.setBeforeDate': 'Вы установили начало действия тарифа на прошедшую дату, из-за этого дата следующего платежа изменилась с',
  'editTarifTranslater.setStartTarif': 'Вы установили начало действия тарифа на будущую дату, из-за этого компания будет в режиме пробного периода с',
  'editTarifTranslater.type': 'Тип учета',
  email: 'Почта',
  email_confirmation: 'Подтверждение e-mail',
  error: 'Ошибка',
  'error.429': 'Слишком много попыток, попробуйте позже.',
  'error.502': 'К сожалению, сервер временно недоступен. Попробуйте повторить действие чуть позже.',
  events: 'Событие',
  'events.consumed': 'Расход топлива, л',
  'events.create': 'Создать событие',
  'events.datetime': 'Дата/Время',
  'events.description': 'Описание',
  'events.deviation_minutes': 'Отклонение, мин',
  'events.duration_hours': 'Длительность ч',
  'events.duration_minutes': 'Длительность, мин',
  'events.filled': 'Заправлено топлива, л',
  'events.finish_date': 'Окончание',
  'events.guaranteed': 'Гарантийный',
  'events.millage': 'Пробег км.',
  'events.motohours': 'Моточасы, ч',
  'events.price': 'Стоимость',
  'events.requiredFields': 'Обязательные поля',
  'events.service_name': 'Сервис',
  'events.start_date': 'Начало',
  'events.stolen': 'Слито топлива, л',
  'events.type_duration_hours': 'Длительность, ч',
  'events.type_name': 'Тип события',
  'events.update': 'Редактировать событие',
  'events.user_id': 'Водитель',
  'events.validation.datetime': 'Введите дату и время',
  'events.validation.number': 'Должно быть числом больше 0',
  'events.validation.required': 'Обязательное поле',
  'events.show': 'Просмотр события',
  'events.imageview.use.keyboard': 'Используйте клавиши',
  'events.imageview.use.keyboard1': 'для переключения между изображениями',
  'events.imageview.images': 'Изображения:',
  'events.imageview.maxsize.msg': 'Нельзя добавить изображения размер которых более 10мб.',
  'events.validation.driver': 'Выберите водителя',
  excel: 'Excel',
  'fast_track.close': 'Скрыть быстрый трек',
  'feedback.description': 'Поделитесь с нами своим опытом пользования нашим продуктом',
  'feedback.fill_area': 'Заполните поле',
  'feedback.message_send': 'Ваш запрос отправлен. Спасибо за обращение!',
  'feedback.question_offer': 'ВопросПредложение',
  'feedback.send': 'Отправить',
  'feedback.yours': 'Ваш отзыв',
  'feedbackTranslater.addSomeTerminals': 'Добавление нескольких терминалов при переносе на новую строчку.',
  'feedbackTranslater.error.name': 'Заполните поле "Имя компании"',
  'feedbackTranslater.error.time': 'Заполните поле "Часовой пояс"',
  'feedbackTranslater.save': 'Сохранить',
  'feedbackTranslater.selectCompany': 'Переключиться в выбранную компанию',
  fill_field: 'Поиск по объектам',
  'formula.add': 'Добавить',
  'formula.errors.formula': 'Введите формулу',
  'formula.errors.name': 'Введите название',
  'formula.extra_fields_sensors': 'Добавить доп. поля и датчики',
  'formula.tooltip': 'В формуле можно использовать арифметически операции +,-,/,*, скобки )(, любые цифры',
  'geozones.add': 'Добавить',
  'geozones.blue': 'Синий',
  'geozones.color': 'Цвет',
  'geozones.create': 'Добавление геозон',
  'geozones.delete': 'Удалить',
  'geozones.edit': 'Редактирование геозоны',
  'geozones.editor': 'Редактирование',
  'geozones.errors.draw_circle': 'Нарисуйте круг!',
  'geozones.errors.min_dots_line': 'Нужно минимум 2 точки для линии!',
  'geozones.errors.min_dots_polygon': 'Нужно минимум 3 точки для полигона!',
  'geozones.errors.name': 'Введите имя!',
  'geozones.errors.put_marker': 'Поставьте маркере на карту!',
  'geozones.errors.width': 'Введите правильное значение ширины!',
  'geozones.figure': 'Фигура',
  'geozones.green': 'Зеленый',
  'geozones.grey': 'Серый',
  'geozones.hectar': 'га',
  'geozones.km': 'км',
  'geozones.library': 'Справочник геозон',
  'geozones.meter': 'м',
  'geozones.name': 'Название',
  'geozones.orange': 'Оранжевый',
  'geozones.red': 'Красный',
  'geozones.square': 'Площадь:',
  'geozones.type': 'Тип',
  'geozones.unitArea': 'Единицы измерения площади:',
  'geozones.use_as_address': 'Использовать как адрес',
  'geozones.never_cluster': 'Никогда не кластеризовать ',
  'geozones.width': 'Толщина',
  'geozones_lang.geozones_enter': 'Вход в геозоны',
  'geozones_lang.geozones_exit': 'Выход из геозон',
  'guide.back': 'Назад',
  'guide.company_workplace': 'Рабочее место компании',
  'guide.instructions': 'Инструкции',
  'guide.personal_account': 'Личный кабинет дилера',
  'guide.quiestion_offer': 'ВопросПредложение',
  'guide.short_instruction': 'Краткие инструкции',
  'guide.title': 'Справка',
  help: 'Справка',
  'helper.admin.text': 'Для доступа в панель администратора вам нужно зарегистрироваться как интегратор или запросить демонстрацию платформы нашим специалистом.',
  'helper.content_text': 'Для работы с датчиками:',
  'helper.content_text_list.five': 'В датчике вы сможете выбрать параметры и настроить их тарировки для этого нажмите на',
  'helper.content_text_list.four': 'Вы увидите список датчиков в объекте. Если список пуст, вы можете сами добавить датчики, нажав на кнопку',
  'helper.content_text_list.one': '1. Откройте модуль “Объекты”',
  'helper.content_text_list.seven': 'Для этого в поле “Формула” выберите последний пункт "Выражение".',
  'helper.content_text_list.six': 'По мимо стандартных формул, для параметров вы можете создавать собственные выражения с возможностью обращаться к сырым данным датчика.',
  'helper.content_text_list.three': '3. В верхнем левом углу выберите “Датчики”.',
  'helper.content_text_list.two': '2. Выберите объект кликом по названию из списка',
  'helper.description_text': 'В демо режиме вам не доступна панель администратора и возможность создавать/редактировать сущности (объекты, геозоны и тд.).',
  'helper.footer.account': 'Зарегистрируйте аккаунт',
  'helper.footer.get_free': '(Получите 30 дней бесплатного пробного периода)',
  'helper.list.five.title': 'Панель администратора',
  'helper.list.four.title': 'Мониторинг',
  'helper.list.one.content': 'Создавайте и редактируйте объекты. Добавляйте датчики, ТО, смены, прицепы и тд.',
  'helper.list.one.link': 'Видео: "Создание объекта"',
  'helper.list.one.title': 'Объекты',
  'helper.list.three.title': 'Отчеты',
  'helper.list.two.content': 'Для работы с датчиками: 1. Откройте модуль "Объекты". 2. Выберите объект кликом по названию из списка. 3. В верхнем левом углу выберите "Датчики".',
  'helper.list.two.title': 'Датчики',
  'helper.monitoring.colors.one': '— Данные пришли мене 15 минут назад',
  'helper.monitoring.colors.three': '— Данные пришли более 30 минут назад',
  'helper.monitoring.colors.two': '— Данные пришли мене 30 минут назад',
  'helper.monitoring.statuses.four': '— Объект находится в движении',
  'helper.monitoring.statuses.one': '— Объект стоит',
  'helper.monitoring.statuses.text': 'Объект может иметь один из 4 статусов:',
  'helper.monitoring.statuses.three': '— Объект находится в движении',
  'helper.monitoring.statuses.two': '— Объект стоит с включённым зажиганием',
  'helper.monitoring.text_one': 'Отслеживайте актуальное состояние ТС.',
  'helper.monitoring.text_three': 'Так же в мониторинге вам доступны быстрые действия связанные с объектом. Например вы можете построить “Быстрый трек” (трек за день, неделю или месяц).',
  'helper.monitoring.text_two': 'Чтобы вы быстро понимали как давно пришли данные по объекту мы добавили цветовую индикацию:',
  'helper.objects': 'Создавайте и редактируйте объекты. Добавляйте датчики, ТО, смены, прицепы и тд.',
  'helper.reports.text': 'Сформируйте отчет по объекту или группе объектов. Выберите один из 25 отчетов или создайте свой при помощи редактора шаблона отчетов.',
  'helper.reports.video_base_reports': 'Видео: “Базовые отчеты”',
  'helper.reports.video_create_template': 'Видео: “Создание шаблонов”',
  'helper.video': 'Видео: “Создание объекта”',
  'helper.video_add_sensor': 'Видео: "Добавление датчиков"',
  'helperTranslater.helper': 'Помощник',
  hide: 'СТЕРЕТЬ',
  'hide.all': 'Все элементы',
  'hide.all.text': 'Скрыть все элементы',
  'hide.events': 'События',
  'hide.events.text': 'Скрыть события на карте',
  'hide.geozones': 'Настройки работы с геозонами',
  'hide.geozones.text': 'Скрыть геозоны на карте',
  'hide.notifications': 'Настройки уведомлений',
  'hide.notifications.text': 'Скрыть уведомления на карте',
  'hide.objects': 'Объекты',
  'hide.objects.text': 'Скрыть объекты на карте',
  'hide.title': 'Видимость на карте',
  'hide.tracks': 'Треки',
  'hide.tracks.direction_of_movement': 'Направление движения',
  'hide.tracks.group': 'Группировать, если расстояние менее',
  'hide.tracks.signatures': 'Скрыть подписи на треках',
  'hide.tracks.text': 'Скрыть треки на карте',
  'hide.trips': 'Маршруты и Рейсы',
  'hide.trips.text': 'Скрыть маршруты и рейсы',
  'history.author': 'Автор',
  'history.create': 'Создание',
  'history.date': 'Дата/Время',
  'history.delete': 'Удаление',
  'history.edit': 'Редактирование',
  'history.event': 'Событие',
  'history.operation': 'Операция',
  'history.title': 'История изменений',
  'importKmlTranslater.error.fileSize': 'Ошибка: Файл должен быть менее 5 Мб.',
  'importKmlTranslater.error.formatKML': 'Формат файла должен быть kml',
  'importKmlTranslater.import': 'Импортировать',
  'importKmlTranslater.inCompany': 'В компанию',
  'importKmlTranslater.selectFile': 'Выбрать файл',
  'importKmlTranslater.selectFileKML': 'Выберите файл KML с геозонами',
  'importKmlTranslater.title': 'Импорт из KML',
  'importWialonTranslater.back': 'Назад',
  'importWialonTranslater.fileObject': 'Выберите файл с объектами из Wialon',
  'importWialonTranslater.findEquipment': 'Обнаружены следующие устройства, выберите для них протоколы:',
  'importWialonTranslater.findedParametrs': 'Обнаружены следующие параметры выбранные в датчиках, проверьте их соответствие. В будущем вы сможете их изменить.',
  'importWialonTranslater.import': 'Импортировать',
  'importWialonTranslater.inCompany': 'В компанию',
  'importWialonTranslater.next': 'Далее',
  'importWialonTranslater.selectFile': 'Выбрать файл',
  'importWialonTranslater.selectProtocol': 'Выберите протокол',
  'importWialonTranslater.title': 'Импорт из Wialon',
  'importWialonTranslater.typeFiles': '.wlp, .xml или zip архив таких файлов',
  in: 'В',
  'integratorsTranslater.INN': 'ИНН',
  'integratorsTranslater.addParameter': 'Добавление интегратора',
  'integratorsTranslater.address': 'Адрес',
  'integratorsTranslater.cancel': 'Отменить',
  'integratorsTranslater.change': 'Изменить',
  'integratorsTranslater.contactPerson': 'Контактное лицо',
  'integratorsTranslater.delete': 'Удалить',
  'integratorsTranslater.documentMobileApp': 'Соглашение об использовании мобильного приложения будет отображаться вашим клиентам',
  'integratorsTranslater.editParameter': 'Редактирование интегратора',
  'integratorsTranslater.email': 'Email',
  'integratorsTranslater.enterName': 'Введите Название',
  'integratorsTranslater.fieldMiss': 'Заполните поле',
  'integratorsTranslater.fileSize': 'Размер файла не должен быть больше 10мб',
  'integratorsTranslater.idShop': 'Идентификатор магазина',
  'integratorsTranslater.moveOferta': 'Поместите оферту сюда или нажмите для выбора',
  'integratorsTranslater.name': 'Название',
  'integratorsTranslater.numberCallCenter': 'Номер контактного центра',
  'integratorsTranslater.ofertaLoaded': 'Оферта загружена',
  'integratorsTranslater.password': 'Пароль',
  'integratorsTranslater.paySystem': 'Платежная система',
  'integratorsTranslater.paymentInforamion': 'Платёжная информация',
  'integratorsTranslater.periodPayment': 'Период оплаты (дни)',
  'integratorsTranslater.priceObject': 'Стоимость за объект',
  'integratorsTranslater.secretKey': 'Секретный ключ',
  'integratorsTranslater.services': 'Услуги',
  'integratorsTranslater.smsTariff': 'Смс-тариф',
  'integratorsTranslater.tariffDefault': 'Тариф по умолчанию для новых компаний',
  'integratorsTranslater.tariffDefaultQR': 'Тариф по умолчанию для регистраций по QR',
  'integratorsTranslater.tariffDescription': 'Описание тарифа',
  'integratorsTranslater.tryPeriod': 'Пробный период (дней)',
  'integratorsTranslater.typeAccount': 'Тип учета',
  'integratorsTranslater.typeOrganization': 'Тип организации',
  'inviteUserTranslater.back': 'Назад',
  'inviteUserTranslater.enterNumber': 'Введите номер телефона в формате +XXXXXXXXXX или XXXXXXXXXX',
  'inviteUserTranslater.invite': 'Пригласить',
  'inviteUserTranslater.next': 'Далее',
  'inviteUserTranslater.passwordMustBe': 'Пароль должен быть больше 5 символов',
  'inviteUserTranslater.register': 'Зарегистрироваться',
  'inviteUserTranslater.wrongNumber': 'Неправильный номер телефона',
  'journal-events': 'Журнал событий',
  'journal-events.filter': 'Фильтр',
  km_hour: 'км/ч',
  'lkDiler.admin_panel.step_1': 'Для создания компании в Админ-панели необходимо нажать на "Добавить компанию" в модуле "Компании":',
  'lkDiler.admin_panel.step_2': 'В открывшейся форме создания компании необходимо заполнить имя компании,выбрать часовой пояс и при необходимости добавить 1 или несколько терминалов и нажать "Сохранить". Оставим выбранным пункт "Переключится в выбранную компанию" Вы автоматически попадете в созданную компанию.',
  'lkDiler.admin_panel.step_3': 'Оставим выбранным пункт "Переключится в выбранную компанию" Вы автоматически попадете в созданную компанию.',
  'lkDiler.change_step.step_1': 'По умолчанию в SKIF.PRO во вновь созданных компаниях используется тариф 250 рублей за объект и возможность клиента оплачивать услугу через процессинговый центр компании СКИФ ЭПП. Для того чтобы отключить процессинговый центр и использовать свою схему тарификации просто в Админ-панели установите Тариф для этой Компании 0 руб. за 0 объектов. Для доступа к тарифам выберите “Тарифы” в списке модулей Админ-панели',
  'lkDiler.change_step.step_2': 'Выберите из списка необходимую компанию и нажмите на кнопку “Показать”',
  'lkDiler.change_step.step_3': 'Кликните строку с тарифом, выберите количество объектов 0 и стоимость одного объекта 0, нажмите подтвердить',
  'lkDiler.change_tariff': 'Изменения тарифа',
  'lkDiler.create_company': 'Создание компаний',
  'lkDiler.create_company_step.create': 'Создание компании в Админ-панели',
  'lkDiler.create_company_step.register': 'Регистрация по ссылке-приглашению',
  'lkDiler.create_company_step.title': 'Создание компаний',
  'lkDiler.create_terminal': 'Заведение терминалов',
  'lkDiler.register_link.step_1': 'Для отправки ссылки-приглашения необходимо в списке своих компаний нажать “Пригласить”',
  'lkDiler.register_link.step_2': 'В открывшемся поле необходимо ввести адрес электронной почты сотрудника Компании, которому придет приглашение, перейдя по ссылке в письме он попадет на страницу регистрации новой Компании. Сотрудник, которому придет ссылка станет Администратором созданной Компании',
  'lkDiler.start_terminal.create_terminal': 'Заведение терминалов',
  'lkDiler.start_terminal.step_1': 'Для добавления терминалов, необходимо перейти в модуль терминалы в Вашей Админ-панели и нажать на "+"',
  'lkDiler.start_terminal.step_2': 'Далее Вам необходимо:',
  'lkDiler.start_terminal.step_3': '1. Выбрать компанию из списка Ваших компанию, в которой хотите разрешить использование терминалов.',
  'lkDiler.start_terminal.step_4': '2. Ввести один или несколько imei терминалов, которые хотите добавить. Удобно сразу вставлять список imei разделенных новой строкой.',
  'lkDiler.start_terminal.step_5': '3. Если терминалы подключаются с использованием пароля то можно его тут указать.',
  'lkDiler.start_terminal.step_6': '4. Нажать на кнопку сохранить.',
  'lkDiler.start_terminal.template.step_1': 'В личном кабинете Дилера, Вы можете создавать шаблоны команд для терминалов, для этого перейдите в Админ-панель и выберите модуль “Справочник команд”',
  'lkDiler.start_terminal.template.step_2': 'Нажмите на “+” для добавления команды, в открывшемся окне выберите тип терминала, выберите или заполните название команды и введите саму команды в поле “Текст команды”, нажмите сохранить',
  'lkDiler.start_terminal.template.title': 'Шаблоны команд для терминалов',
  'lkDiler.template_command': 'Шаблоны комманд',
  'lkDiler.title': 'Личный кабинет Дилера',
  loading: 'Загрузка...',
  'loginPage.accept': 'Я принимаю условия изложенные в',
  'loginPage.addObject': 'Добавить объект',
  'loginPage.autoName': 'Имя авто:',
  'loginPage.autoType': 'Тип авто:',
  'loginPage.back': 'Назад',
  'loginPage.btn.send': 'Отправить',
  'loginPage.carName': 'название машины',
  'loginPage.clickOnStartTheBot-': 'Нажмите на Start, бот возвращает ID рассылки для Telegram',
  'loginPage.company.name': 'Имя компании',
  'loginPage.company.submit': 'Войти',
  'loginPage.company.title': 'Выбор компании',
  'loginPage.confirm.submit': 'На главную',
  'loginPage.confirm.text1': 'Компания зарегистрирована.',
  'loginPage.confirm.text2': 'Для подтверждения регистрации, перейдите по ссылке в письме, отправленном на указанную почту',
  'loginPage.confirm.title': 'Подтверждение',
  'loginPage.dontHaveAccount': 'Я новый пользователь SKIF.PRO2',
  'loginPage.email': 'E-mail',
  'loginPage.enterCode': 'Введи шестизначный код:',
  'loginPage.enterTheTelegramBot': 'Войдите в телеграм-бот',
  'loginPage.errors.email': 'Введите email',
  'loginPage.errors.emailError': 'Введите существующий email',
  'loginPage.errors.name': 'Введите имя',
  'loginPage.errors.nameCompany': 'Введите имя компании',
  'loginPage.errors.pass': 'Введите пароль',
  'loginPage.errors.region': 'Выберите регион',
  'loginPage.errors.telegramChatId': 'Введите id-рассылки для Telegram',
  'loginPage.haveAccount': 'У меня есть учетная запись',
  'loginPage.hint.companyName': 'Выберите компанию',
  'loginPage.hint.lang': 'Выберите язык',
  'loginPage.hint.timeZone': 'Выберите часовой пояс',
  'loginPage.lang': 'Язык',
  'loginPage.linkTermsOfUse': '"Пользовательского соглашения"',
  'loginPage.links.ifHaveAccount': 'Есть аккаунт?',
  'loginPage.links.ifHaveNotAccount': 'Нет аккаунта?',
  'loginPage.links.login': 'Войти',
  'loginPage.links.lostPass': 'Забыли пароль?',
  'loginPage.links.register': 'Регистрация',
  'loginPage.login': 'Логин',
  'loginPage.login.guest': 'Демо вход',
  'loginPage.login.submit': 'Войти',
  'loginPage.login.title': 'Вход',
  'loginPage.mail': 'Ваш email',
  'loginPage.name': 'Ваше имя',
  'loginPage.name.label': 'Имя:',
  'loginPage.nameCompany': 'Имя компании',
  'loginPage.offer': 'оферте',
  'loginPage.orgName': 'Название вашей организации:',
  'loginPage.orgName.holder': 'название организации',
  'loginPage.pass': 'Пароль',
  'loginPage.passHint': 'Ваш пароль состоит минимум из 4 символов.',
  'loginPage.password': 'Пароль:',
  'loginPage.phoneHolder': 'телефон в формате +XXXXXXXXXXXX',
  'loginPage.recovery.text1': 'Для восстановления аккаунта перейдите',
  'loginPage.recovery.text2': 'по ссылке в письме, отправленном на указанную почту.',
  'loginPage.recovery.title': 'Восстановление',
  'loginPage.recovery.toMain': 'На главную',
  'loginPage.register': 'Зарегистрироваться',
  'loginPage.register.submit': 'Зарегистрироваться',
  'loginPage.register.title': 'Регистрация',
  'loginPage.sixCode': 'шестизначный код:',
  'loginPage.telegramChatId': 'Id-рассылки для Telegram',
  'loginPage.textTermsOfUse': 'Нажимая "Зарегистрироваться" Вы принимаете условия',
  'loginPage.timeZone': 'Часовой пояс',
  'loginPage.timeZones': 'Часовой пояс:',
  'loginPage.userName': 'Имя пользователя',
  'loginPage.validation.acceptTerms': 'пожалуйста, примите условия',
  'loginPage.validation.choosecartype': 'Выберите тип машины',
  'loginPage.validation.codeType': 'шестизначный код должен состоять из заглавных английских букв и цифр',
  'loginPage.validation.email': 'Введите Ваш email',
  'loginPage.validation.email.correctform': 'введите правильный адрес электронной почты',
  'loginPage.validation.enter6code': 'Вы должны ввести шестизначный код',
  'loginPage.validation.entercarname': 'Введите имя машины',
  'loginPage.validation.name': 'Введите Ваше имя',
  'loginPage.validation.password': 'Введите Ваш пароль',
  'loginPage.validation.sixCode': 'Вы должны ввести шестизначный код:',
  'loginPage.validation.telephone': 'Введите Ваш телефон',
  'loginPage.validation.tiemzone': 'Выберите часовой пояс',
  'loginPage.yourpassword': 'Ваш пароль',
  'loginPass.changePass.adminPass': 'Введите пароль полученный от администратора',
  'loginPass.changePass.confirmNewPass': 'Подтвердите пароль',
  'loginPass.changePass.current': 'Текущий пароль',
  'loginPass.changePass.empty': 'Если оставить поле пустым,Вам придет подтверждение о смене пароля на электронную почту',
  'loginPass.changePass.inputNewPass': 'Введите новый пароль',
  'loginPass.changePass.inputPass': 'Введите пароль',
  'loginPass.changePass.newPass': 'Новый пароль',
  'loginPass.changePass.newPass_confirm': 'Новый пароль еще раз',
  'loginPass.changePass.old_pass': 'Старый пароль',
  'loginPass.changePass.repeatNewPass': 'Повторите пароль',
  'loginPass.changePass.submit': 'Войти',
  'loginPass.changePass.title': 'Смена пароля',
  'loginPass.errors.lengthPass': 'Пароль должен быть от 5 до 25 символов',
  'loginPass.errors.lengthPass.strongPass.adminUser': 'Пароль должен содержать от 15 до 25 символов и состоять как минимум из одной заглавной английской буквы, как минимум одной строчной английской буквы и как минимум одного специального символа (~ ! @ # $ % ^ & * ( ) - = + [ "{ ] "} | ; : " " , < . > / ?)',
  'loginPass.errors.lengthPass.strongPass.regularUser': 'Пароль должен содержать от 8 до 25 символов и состоять как минимум из одной заглавной английской буквы, как минимум одной строчной английской буквы и как минимум одного специального символа (~ ! @ # $ % ^ & * ( ) - = + [ "{ ] "} | ; : " " , < . > / ?)',
  'loginPass.errors.newPass': 'Введите пароль еще раз',
  'loginPass.errors.notMatchPass': 'Введенные пароли не совпадают',
  'loginPass.errors.pass': 'Введите пароль',
  'loginPass.recovery.back': 'Назад',
  'loginPass.recovery.content': 'Для восстановления аккаунта, перейдите по ссылке в письме,отправленном на эл. почту',
  'loginPass.recovery.hint': 'На указанный e-mail будет отправлено письмо с ссылкой на восстановление пароля',
  'loginPass.recovery.title': 'Восстановление пароля',
  logout: 'Выйти',
  'mailing.adresses': 'Адресаты',
  'mailing.adresses.warning': 'Введите адресатов рассылки!',
  'mailing.chooseGroup': 'Выберите группу!',
  'mailing.chooseObject': 'Выберите объект!',
  'mailing.create': 'Создать рассылку',
  'mailing.date': 'Дата',
  'mailing.daysweek': 'Дни недели',
  'mailing.daysweek.warning': 'Введите дни недели!',
  'mailing.edit': 'Изменить',
  'mailing.enterName': 'Введите имя!',
  'mailing.isActive': 'Активность',
  'mailing.mailing': 'Рассылка',
  'mailing.name': 'Название',
  'mailing.object': 'Объект',
  'mailing.objectGroup': 'Группа объектов',
  'mailing.periodicity': 'Периодичность',
  'mailing.periodicity.warning': 'Введите периодичность!',
  'mailing.subject': 'Тема сообщения',
  'mailing.subject.warning': 'Введите сообщение!',
  'mailing.template': 'Выбор шаблона',
  'mailing.timeDateSent': 'Дата/время последней отправки',
  'mailing.title': 'Справочник рассылок',
  'mailing.vehicle': 'Выбор транспортного средства',
  'mainPage.back': 'На главную!',
  'mainPage.sorry': 'К сожалению ваша ссылка уже не работает. Попробуйте повторить операцию.',
  'mainPage.title': 'Добро пожаловать в SKIF!',
  'map.adrress_not_found': 'Адрес не найден',
  'map.bing': 'Bing',
  'map.bingSatellite': 'Bing Satellite',
  'map.googleHybrid': 'Google Hybrid',
  'map.googleRoadMap': 'Google Roadmap',
  'map.googleSatelite': 'Google Sattelite',
  'map.googleTerrain': 'Google Terrain',
  'map.here': 'Here',
  'map.osm': 'OSM',
  'map.search': 'Поиск точного адреса',
  'map.style': 'Стиль карты',
  'map.title': 'Карта',
  'map.yandex': 'Яндекс Карты',
  'measure.cancel': 'Отменить',
  'measure.clear': 'ОЧИСТИТЬ',
  'measure.description': 'Вы можете изменить измерение путем перемещения точки',
  'measure.distance': 'Расстояние',
  'measure.finish': 'Закончить измерения',
  'measure.lastPoint': 'Последняя точка',
  'measure.line': 'Линия',
  'measure.perimeter': 'Периметр',
  'measure.polygon': 'Полигон',
  'measure.region': 'Область',
  'measure.square': 'Площадь',
  'measure.title': 'Измерение расстояний и площади',
  'menuSelect.geozones': 'Геозоны',
  'menuSelect.monitoring': 'Мониторинг',
  'menuSelect.notifications': 'Уведомления',
  'menuSelect.objects': 'Объекты',
  'menuSelect.race': 'Рейсы',
  'menuSelect.reports': 'Отчеты',
  'menuSelect.routes': 'Маршруты',
  'menuSelect.users': 'Пользователи',
  monitoring: 'Мониторинг',
  'monitoring.accept': 'Применить',
  'monitoring.activeFilters': 'Активные фильтры',
  'monitoring.add-objects': 'Добавить объекты',
  'monitoring.addFilter': 'Добавить фильтр',
  'monitoring.apply_filter': 'Применить фильтры',
  'monitoring.change-object': 'Изменить объект',
  'monitoring.choose': 'Выбрать',
  'monitoring.columns.name': 'Название',
  'monitoring.command_response': 'Ответ',
  'monitoring.commands': 'Команды',
  'monitoring.commands.columns.command': 'Команда',
  'monitoring.commands.columns.data': 'Дата/Время',
  'monitoring.commands.columns.status': 'Статус',
  'monitoring.commands.error_message': 'Заполните поле "Выберите объект" и "Команда"',
  'monitoring.commands.exit_number': 'Номер выхода',
  'monitoring.commands.object': 'Объект',
  'monitoring.create-event': 'Создать событие',
  'monitoring.date-and-time': 'Дата и время',
  'monitoring.datepoint': 'Был на связи',
  'monitoring.display_all': 'Отобразить все',
  'monitoring.displayed_objects': 'Отображено объектов:',
  'monitoring.distance_group': 'Группировать, если расстояние менее (точек экрана):',
  'monitoring.editObject': 'Редактировать объект',
  'monitoring.enter-description': 'Введите описание',
  'monitoring.fast-report': 'Быстрый отчет',
  'monitoring.fast-track': 'Быстрый трек',
  'monitoring.fast_tracks': 'Быстрые треки',
  'monitoring.fast_tracks.month': 'За месяц',
  'monitoring.fast_tracks.today': 'За сегодня',
  'monitoring.fast_tracks.week': 'За неделю',
  'monitoring.fast_tracks.yesterday': 'За вчера',
  'monitoring.filter-journal-events': 'Фильтр журнала событий',
  'monitoring.filter.customFields': 'Доп. поля',
  'monitoring.filter.driver': 'Водитель',
  'monitoring.filter.event': 'События',
  'monitoring.filter.fieldsEmpty': 'Все поля должны быть заполнены',
  'monitoring.filter.imei': 'IMEI',
  'monitoring.filter.name': 'Имя',
  'monitoring.filter.phoneNumber': 'Телефоны',
  'monitoring.filter.sensorName': 'Имена датчиков',
  'monitoring.filter.sensorParam': 'Параметр датчиков',
  'monitoring.filter.sensorType': 'Типы датчиков',
  'monitoring.filterMax': 'Нельзя добавить более 30 фильтров',
  'monitoring.filters': 'Фильтры',
  'monitoring.graph': 'График',
  'monitoring.grouping_on_map': 'Группировка на карте',
  'monitoring.groups.edit': 'Редактирование',
  'monitoring.map_work_settings': 'Настройки работы с картой',
  'monitoring.maxUnits': 'Нельзя отобразить более 300 объектов сразу, воспользуйтесь фильтром для уменьшения количества объектов.',
  'monitoring.object_search': 'Поиск по названию объекта',
  'monitoring.objects': 'Объекты',
  'monitoring.objects_grouping': 'Группировка объектов',
  'monitoring.objects_list': 'Список объектов:',
  'monitoring.objects_on_map': 'Объекты на карте:',
  'monitoring.position': 'Положение',
  'monitoring.registration-event': 'Регистрация события',
  'monitoring.search.custom_fields': 'Доп. поля',
  'monitoring.search.driver': 'Водитель',
  'monitoring.search.events': 'События',
  'monitoring.search.groups': 'Группы',
  'monitoring.search.imei': 'IMEI',
  'monitoring.search.name': 'Имя',
  'monitoring.search.phonenumber': 'Телефоны',
  'monitoring.search.sensors_name': 'Имена датчиков',
  'monitoring.search.sensors_param': 'Параметр датчиков',
  'monitoring.search.sensors_types': 'Типы датчиков',
  'monitoring.searchHolder': 'зажиг, топливо',
  'monitoring.send-command': 'Отправит команду',
  'monitoring.sendCommand-title': 'Отправить команду',
  'monitoring.send_command': 'Отправить команду',
  'monitoring.shape_size': 'Размер формы:',
  'monitoring.show_address': 'Показывать адрес объекта',
  'monitoring.show_annotation_on_click': 'Показывать аннотацию при клике на объект в списке мониторинга',
  'monitoring.terminal_on': 'Терминал подключён',
  'monitoring.to_reports': 'В отчеты',
  'monitoring.track_all': 'Отслеживать все',
  'monitoring.trips': 'Поездки',
  'monitoring.type-event': 'Тип события',
  'monitoring.view-events': 'Вид события',
  'monitoring.violation': 'Нарушение',
  'monitoringListTranslater.withoutgroup': 'Без группы',
  name: 'Имя',
  'name.column': 'Название колонки',
  'nav.admin': 'Админ-панель',
  'nav.geozones': 'Геозоны',
  'nav.mailing': 'Рассылка',
  'nav.monitoring': 'Мониторинг',
  'nav.notifications': 'Уведомления',
  'nav.objects': 'Объекты',
  'nav.reports': 'Отчеты',
  'nav.trips': 'Рейсы',
  'nav.users': 'Пользователи',
  'new_units.add.add_field': 'Добавить поле',
  'new_units.add.couplings': 'Добавить',
  'new_units.add.groups': 'Добавить группу',
  'new_units.add.links': 'Создать ссылку',
  'new_units.add.objects': 'Добавить объект',
  'new_units.add.parameters': 'Добавить параметры',
  'new_units.add.service': 'Добавить ТО',
  'new_units.add.shifts': 'Добавить',
  'new_units.add.trailers': 'Добавить прицеп',
  'new_units.apply_changes': 'Применить изменения',
  'new_units.approve': 'Подтвердить',
  'new_units.cancel': 'Отменить изменения',
  'new_units.create': 'Создать',
  'new_units.delete': 'удалить',
  'new_units.edit': 'Редактировать',
  'new_units.footer.cancel': 'Отменить',
  'new_units.footer.save': 'Сохранить',
  'new_units.groups.errors.create': 'Упс... Возникла проблема при создании',
  'new_units.groups.errors.delete': 'В процессе удаления групп возникла ошибка!',
  'new_units.groups.errors.edit': 'Упс... Возникла проблема при редактировании',
  'new_units.groups.errors.name': 'Введите имя!',
  'new_units.groups.errors.validation': 'Заполните обязательные поля!',
  'new_units.groups.no_groups': 'Не создана ни одной группы объектов',
  'new_units.groups.search': 'Поиск по группам',
  'new_units.groups.search_options.name': 'Имя',
  'new_units.groups.search_options.object': 'Объект',
  'new_units.groups.success.create': 'Группа успешна создана!',
  'new_units.groups.success.delete': 'Группы успешно удалены!',
  'new_units.groups.success.edit': 'Группа успешна отредактирована!',
  'new_units.groups.table_columns.group_name': 'Название группы',
  'new_units.groups.table_columns.objects': 'Объектов',
  'new_units.groups.table_columns.users_count': 'Пользователи',
  'new_units.links.accessEnd': 'Дата завершения доступа',
  'new_units.links.accessStart': 'Дата начала доступа',
  'new_units.links.create': 'Создать ссылку доступа',
  'new_units.links.description': 'Описание',
  'new_units.links.edit': 'Редактировать ссылку',
  'new_units.links.empty': 'Не выбран',
  'new_units.links.errors.access_end': 'Выберите дату завершения!',
  'new_units.links.errors.access_start': 'Выберите дату начала!',
  'new_units.links.errors.create': 'В процессе создания ссылки доступа возникла ошибка!',
  'new_units.links.errors.delete': 'В процессе удаления ссылок доступа возникла ошибка!',
  'new_units.links.errors.edit': 'В процессе редактирования ссылки доступа возникла ошибка!',
  'new_units.links.errors.unit': 'Выберите объект!',
  'new_units.links.link': 'Ссылка',
  'new_units.links.no_links': 'Список ссылок доступа пуст',
  'new_units.links.object': 'Объект',
  'new_units.links.placeholder': 'Длинное описание ....',
  'new_units.links.search': 'Поиск по ссылкам',
  'new_units.links.search_options.object': 'Объект',
  'new_units.links.search_options.status': 'Статус',
  'new_units.links.status.active_off': 'Активна (выкл.)',
  'new_units.links.status.active_on': 'Активна (вкл.)',
  'new_units.links.status.inactive': 'Не активна',
  'new_units.links.status.planned': 'Запланирована',
  'new_units.links.success.create': 'Ссылка доступа успешна создана!',
  'new_units.links.success.create_imei': 'Imei код успешно создан!',
  'new_units.links.success.delete': 'Ссылки доступа успешно удалены!',
  'new_units.links.success.edit': 'Ссылка доступа успешна отредактирована!',
  'new_units.links.success.edit_imei': 'Imei код успешно отредактирован!',
  'new_units.links.table_columns.active_from': 'Активна с',
  'new_units.links.table_columns.active_to': 'Активна по',
  'new_units.links.table_columns.object': 'Объект',
  'new_units.links.table_columns.status': 'Статус',
  'new_units.not_selected': 'не выбран',
  'new_units.objects.approved': 'ПОДТВЕРЖДЕННЫЕ',
  'new_units.objects.create': 'Создание объекта',
  'new_units.objects.edit': 'Редактирование объекта',
  'new_units.objects.errors.delete': 'В процессе удаления объектов возникла ошибка!',
  'new_units.objects.fields.basic.errors.custom_imei': 'Имей заблокирован!',
  'new_units.objects.fields.basic.errors.imei': 'Выберите уникальный ID!',
  'new_units.objects.fields.basic.errors.name': 'Введите имя!',
  'new_units.objects.fields.basic.errors.phonenumber': 'Введите телефон!',
  'new_units.objects.fields.basic.errors.terminal_type': 'Выберите тип устройства!',
  'new_units.objects.fields.basic.errors.type': 'Выберите тип объекта!',
  'new_units.objects.fields.basic.gadget': 'Устройство (терминал):',
  'new_units.objects.fields.basic.groups': 'Группы',
  'new_units.objects.fields.basic.imei': 'IMEI:',
  'new_units.objects.fields.basic.name': 'Название объекта:',
  'new_units.objects.fields.basic.note': 'Вы можете не указывать название устройства. Для корректной работы достаточно указать протокол.',
  'new_units.objects.fields.basic.port': 'Порт:',
  'new_units.objects.fields.basic.protocol': 'Протокол обмена данными:',
  'new_units.objects.fields.basic.server': 'Сервер получения данных:',
  'new_units.objects.fields.basic.sim_1': 'Sim1:',
  'new_units.objects.fields.basic.sim_2': 'Sim2:',
  'new_units.objects.fields.basic.type': 'Тип объекта:',
  'new_units.objects.fields.couplings.date_filter': 'Фильтр по дате:',
  'new_units.objects.fields.couplings.end': 'По',
  'new_units.objects.fields.couplings.end_date': 'Дата завершения:',
  'new_units.objects.fields.couplings.errors.already_deleted': 'Вы уже удалили эту сцепку, если вы хотите вернуть нажмите на кнопку отмены ниже.',
  'new_units.objects.fields.couplings.errors.approved': 'Пожалуйста подтвердите текущий прицеп, чтобы создать новую сцепку!',
  'new_units.objects.fields.couplings.errors.couplig_end': 'Выберите дату завершения!',
  'new_units.objects.fields.couplings.errors.couplig_start': 'Выберите дату начала!',
  'new_units.objects.fields.couplings.errors.trailer': 'Выберите прицеп!',
  'new_units.objects.fields.couplings.errors.work_type': 'Выберите тип работы!',
  'new_units.objects.fields.couplings.max_speed': 'Макс. скорость (км/ч):',
  'new_units.objects.fields.couplings.min_speed': 'Мин. скорость (км/ч):',
  'new_units.objects.fields.couplings.no_couplings': 'Работа с прицепами не запланирована',
  'new_units.objects.fields.couplings.note': 'Интервал использования прицепа пересекается с другим интервалом.',
  'new_units.objects.fields.couplings.sensor_mask': 'Маска названия датчиков:',
  'new_units.objects.fields.couplings.start': 'С',
  'new_units.objects.fields.couplings.start_date': 'Дата начала:',
  'new_units.objects.fields.couplings.success.approved': 'Сцепка была успешна подтверждена!',
  'new_units.objects.fields.couplings.success.updated': 'Сцепка была успешна обновлена',
  'new_units.objects.fields.couplings.table_columns.from': 'С',
  'new_units.objects.fields.couplings.table_columns.to': 'По',
  'new_units.objects.fields.couplings.table_columns.trailer': 'Прицеп',
  'new_units.objects.fields.couplings.title': 'Планирование:',
  'new_units.objects.fields.couplings.trailer': 'Прицеп',
  'new_units.objects.fields.couplings.work_type': 'Тип работ:',
  'new_units.objects.fields.parameters.errors.correct_values': 'Заполните корректными значениями чтобы перейти в раздел!',
  'new_units.objects.fields.parameters.errors.wrong_value': 'Неверное значение!',
  'new_units.objects.fields.parameters.name': 'Название:',
  'new_units.objects.fields.parameters.no_parameters': 'Вы не добавили ни одного дополнительного параметра',
  'new_units.objects.fields.parameters.switch.hint': 'Показывать в мониторинге',
  'new_units.objects.fields.parameters.switch.not_show_annotation': 'Не отображать в аннотации',
  'new_units.objects.fields.parameters.switch.show_annotation': 'Отображать в аннотации',
  'new_units.objects.fields.parameters.tabs.additional': 'Дополнительно',
  'new_units.objects.fields.parameters.tabs.engine_hours': 'Моточасы',
  'new_units.objects.fields.parameters.tabs.filter_data': 'Фильтрация данных',
  'new_units.objects.fields.parameters.tabs.fuel': 'Топливо',
  'new_units.objects.fields.parameters.tabs.parameters': 'Ваши параметры',
  'new_units.objects.fields.parameters.tabs.trips': 'Поездки',
  'new_units.objects.fields.parameters.tabs.video': 'Видео',
  'new_units.objects.fields.parameters.value': 'Значение:',
  'new_units.objects.fields.parameters.video.hint': 'Введите номера каналов через запятую для отображения видео.',
  'new_units.objects.fields.sensors.fields.connected_sensors.bundle_with_ignition': 'Связать с датчиком',
  'new_units.objects.fields.sensors.fields.connected_sensors.cancel': 'Отменить',
  'new_units.objects.fields.sensors.fields.connected_sensors.connected': 'Связан',
  'new_units.objects.fields.sensors.fields.connected_sensors.title': 'Связка с датчиками',
  'new_units.objects.fields.sensors.fields.errors.duplicate_lower_bound': 'Значения нижних границ не могут повторяться!',
  'new_units.objects.fields.sensors.fields.errors.duplicate_x': 'Значения X не могут повторяться!',
  'new_units.objects.fields.sensors.fields.errors.formula': 'Выберите формулу!',
  'new_units.objects.fields.sensors.fields.errors.invalid_value': 'Вы не можете добавить новую строку, так как в таблице есть невалидное значение.',
  'new_units.objects.fields.sensors.fields.errors.name': 'Введите название датчика!',
  'new_units.objects.fields.sensors.fields.errors.parameter_1': 'Выберите параметр 1!',
  'new_units.objects.fields.sensors.fields.errors.parameter_2': 'Выберите параметр 2!',
  'new_units.objects.fields.sensors.fields.errors.save_validation': 'Исправьте ошибку в текущем датчике, для того чтобы сохранить!',
  'new_units.objects.fields.sensors.fields.errors.smoothing': 'Введите правильное значение!',
  'new_units.objects.fields.sensors.fields.errors.type': 'Выберите тип датчика!',
  'new_units.objects.fields.sensors.fields.errors.validation': 'Исправьте ошибку в текущем датчике, для того чтобы перейти к другому!',
  'new_units.objects.fields.sensors.fields.expression': 'Выражение',
  'new_units.objects.fields.sensors.fields.formula': 'Формула',
  'new_units.objects.fields.sensors.fields.help_info': 'При помощи клика мыши выберите формат работы с параметрами датчика.',
  'new_units.objects.fields.sensors.fields.hidden_in_both': 'Скрыт в отчетах и в мониторинге',
  'new_units.objects.fields.sensors.fields.hidden_in_monitoring': 'Скрыт в мониторинге',
  'new_units.objects.fields.sensors.fields.hidden_in_report': 'Скрыт в отчетах',
  'new_units.objects.fields.sensors.fields.hide_in_monitoring': 'Скрывать в мониторинге',
  'new_units.objects.fields.sensors.fields.hide_in_report': 'Скрывать в отчетах',
  'new_units.objects.fields.sensors.fields.hint.hide': 'Скрыть',
  'new_units.objects.fields.sensors.fields.hint.show': 'Показать',
  'new_units.objects.fields.sensors.fields.hint.title': 'Подсказка',
  'new_units.objects.fields.sensors.fields.multiple_deletion_mode': 'Вы находитесь в режиме удаления датчиков',
  'new_units.objects.fields.sensors.fields.name': 'Название:',
  'new_units.objects.fields.sensors.fields.new_sensor': 'Новый датчик',
  'new_units.objects.fields.sensors.fields.parameters.a': 'А',
  'new_units.objects.fields.sensors.fields.parameters.b': 'Б',
  'new_units.objects.fields.sensors.fields.parameters.clear': 'Очистить таблицу',
  'new_units.objects.fields.sensors.fields.parameters.download_as_csv': 'Скачать X,Y как CSV-файл',
  'new_units.objects.fields.sensors.fields.parameters.download_from_csv': 'Загрузить X и Y из CSV-файла',
  'new_units.objects.fields.sensors.fields.parameters.exist': 'Есть',
  'new_units.objects.fields.sensors.fields.parameters.helpers.degree': 'степень',
  'new_units.objects.fields.sensors.fields.parameters.helpers.param': 'Параметр',
  'new_units.objects.fields.sensors.fields.parameters.helpers.param1': 'param1',
  'new_units.objects.fields.sensors.fields.parameters.helpers.param1_minus_1': 'param1[-1]',
  'new_units.objects.fields.sensors.fields.parameters.helpers.param2': 'param2',
  'new_units.objects.fields.sensors.fields.parameters.helpers.param2_minus_1': 'param2[-1]',
  'new_units.objects.fields.sensors.fields.parameters.helpers.param_minus_1': 'Параметр[-1]',
  'new_units.objects.fields.sensors.fields.parameters.helpers.sensor_name': 'Название д-ка',
  'new_units.objects.fields.sensors.fields.parameters.helpers_description.byte_control': 'побитовый контроль параметра (например param101:3 - третий бит параметра param101)',
  'new_units.objects.fields.sensors.fields.parameters.helpers_description.param': 'обращение к значениям любого параметра из сырых данных',
  'new_units.objects.fields.sensors.fields.parameters.helpers_description.param1': 'обращение к первому параметру(после линейнего преобразование) текущего датчика',
  'new_units.objects.fields.sensors.fields.parameters.helpers_description.param1_minus_1': 'обращение к предыдущему значению первого параметра(после линейнего преобразование) текущего датчика',
  'new_units.objects.fields.sensors.fields.parameters.helpers_description.param2': 'обращение ко второму параметру(после линейнего преобразование) текущего датчика',
  'new_units.objects.fields.sensors.fields.parameters.helpers_description.param2_minus_1': 'обращение к предыдущему значению второго параметра(после линейнего преобразование) текущего датчика',
  'new_units.objects.fields.sensors.fields.parameters.helpers_description.param_minus_1': 'обращение к предыдущему значению параметра из сырых данных',
  'new_units.objects.fields.sensors.fields.parameters.helpers_description.sensor_name': 'обращение к значению других датчиков',
  'new_units.objects.fields.sensors.fields.parameters.hide_graph': 'Скрыть график',
  'new_units.objects.fields.sensors.fields.parameters.lower_bound_short': '(ниж. гран.)',
  'new_units.objects.fields.sensors.fields.parameters.lower_bound_x': 'Нижняя граница X:',
  'new_units.objects.fields.sensors.fields.parameters.param_option_1': 'Параметр 1:',
  'new_units.objects.fields.sensors.fields.parameters.param_option_2': 'Параметр 2:',
  'new_units.objects.fields.sensors.fields.parameters.param_title_1': 'Параметр 1',
  'new_units.objects.fields.sensors.fields.parameters.param_title_2': 'Параметр 2',
  'new_units.objects.fields.sensors.fields.parameters.param_title_s_1': 'S1',
  'new_units.objects.fields.sensors.fields.parameters.param_title_s_2': 'S2',
  'new_units.objects.fields.sensors.fields.parameters.related_tables': 'Связанные таблицы',
  'new_units.objects.fields.sensors.fields.parameters.sensor_calibration': 'Тарировка датчика',
  'new_units.objects.fields.sensors.fields.parameters.show_graph': 'Показать график',
  'new_units.objects.fields.sensors.fields.parameters.smoothing_coefficient': 'Коэффициент сглаживания',
  'new_units.objects.fields.sensors.fields.parameters.smoothing_coefficient_range': '(от 0 до 999)',
  'new_units.objects.fields.sensors.fields.parameters.type_filter': 'Тип фильтрации',
  'new_units.objects.fields.sensors.fields.parameters.upper_bound_x': 'Верхняя граница Х:',
  'new_units.objects.fields.sensors.fields.parameters.x': 'X',
  'new_units.objects.fields.sensors.fields.parameters.y': 'Y',
  'new_units.objects.fields.sensors.fields.sensor_type': 'Тип датчика:',
  'new_units.objects.fields.sensors.fields.technical_params.add_params': 'Добавить тех. параметр',
  'new_units.objects.fields.sensors.fields.technical_params.errors.wrong_value': 'Неверное значение!',
  'new_units.objects.fields.sensors.fields.technical_params.show_annotation': 'Отображении параметра в аннотации объекта.',
  'new_units.objects.fields.sensors.fields.technical_params.title': 'Технические параметры',
  'new_units.objects.fields.sensors.no_sensors': 'В объекте не создан ни один датчик',
  'new_units.objects.fields.sensors.output_text': 'Выводимый текст',
  'new_units.objects.fields.sensors.title': 'Датчики:',
  'new_units.objects.fields.sensors.value': 'Значение',
  'new_units.objects.fields.sensors.value_to_sensor': 'Преобразовывать значения в текст:',
  'new_units.objects.fields.sensors.value_to_sensor_text': 'Превратите числовое значение датчика в текстовое. Для этого укажите значения и текстовые ключи для них. Используйте линейное преобразование датчика для работы с диапазонами значений.',
  'new_units.objects.fields.service.days': 'Дни',
  'new_units.objects.fields.service.errors.already_deleted': 'Вы уже удалили эту смену, если вы хотите вернуть нажмите на кнопку отмены ниже.',
  'new_units.objects.fields.service.errors.checkbox': 'Выберите 1 из типов интервала!',
  'new_units.objects.fields.service.errors.description': 'Введите описание!',
  'new_units.objects.fields.service.errors.intervalDays': 'Введите интервал (дни)!',
  'new_units.objects.fields.service.errors.intervalKm': 'Введите текущий пробег (км)!',
  'new_units.objects.fields.service.errors.intervalMotohours': 'Введите интервал (моточасы)!',
  'new_units.objects.fields.service.errors.lastDays': 'Выберите дату последнего ТО!',
  'new_units.objects.fields.service.errors.lastKm': 'Введите текущий пробег (км)!',
  'new_units.objects.fields.service.errors.lastMotohours': 'Введите последний (раз)!',
  'new_units.objects.fields.service.errors.name': 'Введите название!',
  'new_units.objects.fields.service.errors.validation': 'Пожалуйста заполните все обязательные поля!',
  'new_units.objects.fields.service.fields.ativate_engine_hours': 'Активировать интервал по моточасам',
  'new_units.objects.fields.service.fields.current_mileage': 'Тек. пробег (км):',
  'new_units.objects.fields.service.fields.days_period': 'Активировать интервал по дням',
  'new_units.objects.fields.service.fields.description': 'Описание:',
  'new_units.objects.fields.service.fields.engine_hours': 'Интервал (M/ч):',
  'new_units.objects.fields.service.fields.interval': 'Интервал (км):',
  'new_units.objects.fields.service.fields.interval_days': 'Интервал в днях:',
  'new_units.objects.fields.service.fields.last_serviced': 'Дата последнего ТО:',
  'new_units.objects.fields.service.fields.last_times': 'Последнее раз:',
  'new_units.objects.fields.service.fields.mileage_period': 'Активировать интервал по пробегу',
  'new_units.objects.fields.service.fields.service': 'Сервис:',
  'new_units.objects.fields.service.fields.mileage_period.alert': 'Для отчета ТО требуется добавить датчик пробега',
  'new_units.objects.fields.service.fields.mileage_period.alertSecond': 'Для отчета ТО требуется добавить датчик абсолютных моточасов',
  'new_units.objects.fields.service.mileage': 'Пробег/ М.ч.',
  'new_units.objects.fields.service.no_service': 'Список ТО пуст',
  'new_units.objects.fields.service.service': 'Сервис',
  'new_units.objects.fields.service.table_columns.days': 'Дни',
  'new_units.objects.fields.service.table_columns.mileage': 'Пробег/ М.ч.',
  'new_units.objects.fields.service.table_columns.service': 'Сервис',
  'new_units.objects.fields.service.title': 'ТО:',
  'new_units.objects.fields.shifts.additionalFields': 'Дополнительные поля:',
  'new_units.objects.fields.shifts.driver': 'Водитель',
  'new_units.objects.fields.shifts.end': 'По',
  'new_units.objects.fields.shifts.errors.already_deleted': 'Вы уже удалили эту смену, если вы хотите вернуть нажмите на кнопку отмены ниже.',
  'new_units.objects.fields.shifts.errors.approved': 'Пожалуйста подтвердите текущую смену, чтоб создать новую!',
  'new_units.objects.fields.shifts.errors.driver': 'Выберите водителя!',
  'new_units.objects.fields.shifts.errors.shift_end': 'Выберите конец смены!',
  'new_units.objects.fields.shifts.errors.shift_start': 'Выберите начало смены!',
  'new_units.objects.fields.shifts.hide_old_shifts': 'Скрыть прошедшие смены',
  'new_units.objects.fields.shifts.name': 'Название:',
  'new_units.objects.fields.shifts.no_shifts': 'Список смен пуст',
  'new_units.objects.fields.shifts.note': 'Интервал использования cмены пересекается с другим интервалом.',
  'new_units.objects.fields.shifts.shift_end': 'Конец смены:',
  'new_units.objects.fields.shifts.shift_start': 'Начало смены:',
  'new_units.objects.fields.shifts.start': 'С',
  'new_units.objects.fields.shifts.success.approved': 'Смена была успешна подтверждена!',
  'new_units.objects.fields.shifts.success.updated': 'Смена была успешна обновлена!',
  'new_units.objects.fields.shifts.table_columns.driver': 'Водитель',
  'new_units.objects.fields.shifts.table_columns.from': 'С',
  'new_units.objects.fields.shifts.table_columns.to': 'По',
  'new_units.objects.fields.shifts.title': 'Смены:',
  'new_units.objects.fields.shifts.value': 'Значение:',
  'new_units.objects.fill': 'Заполнить',
  'new_units.objects.no_objects': 'В систему не добавлен ни один объект',
  'new_units.objects.object': 'Объект:',
  'new_units.objects.saved': 'Сохраненные',
  'new_units.objects.search': 'Поиск по объектам',
  'new_units.objects.search_options.custom_fields': 'Доп. поля',
  'new_units.objects.search_options.events': 'События',
  'new_units.objects.search_options.imei': 'IMEI',
  'new_units.objects.search_options.name': 'Имя',
  'new_units.objects.search_options.groups': 'Группы',
  'new_units.objects.search_options.phonenumber': 'Телефоны',
  'new_units.objects.search_options.sensors_name': 'Имена датчиков',
  'new_units.objects.search_options.sensors_param': 'Параметр датчиков',
  'new_units.objects.search_options.sensors_type': 'Типы датчиков',
  'new_units.objects.success.delete': 'Объекты успешно удалены!',
  'new_units.objects.table_columns.imei': 'IMEI',
  'new_units.objects.table_columns.name': 'Название',
  'new_units.objects.table_columns.protocol': 'Протокол',
  'new_units.objects.table_columns.sensors': 'Датчики',
  'new_units.objects.table_columns.sim_1_2': 'Сим 1,2',
  'new_units.objects.tabs.basic': 'Основные',
  'new_units.objects.tabs.blocked_text': 'Смены, ТО и Сцепки будут доступны после сохранения объекта',
  'new_units.objects.tabs.couplings': 'Cцепки',
  'new_units.objects.tabs.parameters': 'Тех. параметры',
  'new_units.objects.tabs.sensors': 'Датчики',
  'new_units.objects.tabs.service': 'ТО',
  'new_units.objects.tabs.shifts': 'Смены',
  'new_units.placeholder.select': 'Выберите',
  'new_units.recover.button': 'Отменить удаление',
  'new_units.recover.removed.couplings': 'Удалено сцепок:',
  'new_units.recover.removed.parameters': 'Удалено параметров:',
  'new_units.recover.removed.sensors': 'Удалено датчиков:',
  'new_units.recover.removed.service': 'Удалено ТО:',
  'new_units.recover.removed.shifts': 'Удалено смен:',
  'new_units.recover.removed.trailers': 'Удалено прицепов:',
  'new_units.save_modal.close': 'Закрыть форму',
  'new_units.save_modal.continue': 'Продолжить',
  'new_units.save_modal.message': 'Объект и данные успешно сохранены',
  'new_units.tabs.groups': 'Группы',
  'new_units.tabs.links': 'Ссылки доступа',
  'new_units.tabs.objects': 'Объекты',
  'new_units.tabs.trailers': 'Прицепы',
  'new_units.titles.create': 'Создание объекта',
  'new_units.titles.edit': 'Редактирование объекта',
  'new_units.titles.groups': 'Справочник групп',
  'new_units.titles.links': 'Справочник ссылок доступа',
  'new_units.titles.objects': 'Справочник объектов',
  'new_units.titles.trailers': 'Справочник прицепов',
  'new_units.trailers.additionalFields': 'Дополнительные поля:',
  'new_units.trailers.errors.already_deleted': 'Вы уже удалили этот прицеп, если вы хотите вернуть нажмите на кнопку отмены ниже.',
  'new_units.trailers.errors.approved': 'Пожалуйста сохраните текущий прицеп, чтобы создать новый!',
  'new_units.trailers.errors.name': 'Введите имя!',
  'new_units.trailers.errors.validation': 'Заполните все обязательные поля, чтобы перейти к другому прицепу!',
  'new_units.trailers.fields.code': 'Код:',
  'new_units.trailers.fields.description': 'Описание:',
  'new_units.trailers.fields.length': 'Ширина:',
  'new_units.trailers.fields.name': 'Название:',
  'new_units.trailers.fields.value': 'Значение:',
  'new_units.trailers.no_trailers': 'Не создан ни один прицеп',
  'new_units.trailers.save': 'Сохранить',
  'new_units.trailers.search': 'Поиск по прицепам',
  'new_units.trailers.search_options.code': 'Код',
  'new_units.trailers.search_options.name': 'Название',
  'new_units.trailers.success.approved': 'Прицеп был успешно создан!',
  'new_units.trailers.success.delete': 'Прицепы были успешно удалены!',
  'new_units.trailers.success.updated': 'Прицеп был успешно обновлен!',
  'new_units.trailers.table_columns.code': 'Код',
  'new_units.trailers.table_columns.name': 'Имя',
  'new_units.trailers.table_columns.width': 'Ширина',
  'new_units.trailers.title': 'Прицепы:',
  'new_units.unspecified': 'не указан',
  no: 'Нет',
  noMatch: 'Совпадений не найдено',
  no_data: 'Нет данных',
  notification: 'Уведомление',
  'notification.use_speed_description': 'Активация данного чекбокса не позволит водителю разогнать объект, свыше указанной максимальной скорости, нажатием на педаль газа, при входе в выбранные ниже геозоны.',
  'notification_alert.error_points': 'Точки для трека отсутствуют!',
  'notification_alert.success': 'Событие успешно сохранено',
  'notifications.add': 'Добавить уведомление',
  'notifications.adress': 'Адрес',
  'notifications.allowedDowntime': 'Допустимое время простоя',
  'notifications.asEvent': 'Регистрировать как события',
  'notifications.asViolation': 'Регистрировать как нарушение',
  'notifications.byEmail': 'По E-mail',
  'notifications.byPush': 'Push-уведомления',
  'notifications.bySms': 'По Sms',
  'notifications.byTelegram': 'На Telegram',
  'notifications.change': 'Изменить уведомление',
  'notifications.chooseDeliveryType': 'Не выбран ни один тип доставки',
  'notifications.chooseEmail': 'Выберите хотя бы 1 email!',
  'notifications.choosePhone': 'Выберите хотя бы 1 телефон!',
  'notifications.chooseUser': 'Выберите хотя бы одного пользователя',
  'notifications.connexionLost': 'Потеря связи',
  'notifications.control': 'Контроль',
  'notifications.days_to_service': 'Количество дней просрочки ТО',
  'notifications.delivery': 'Доставка',
  'notifications.deliveryMethod': 'Способ доставки',
  'notifications.deviation': 'Отклонение',
  'notifications.drain': 'Слив',
  'notifications.driver': 'Водитель',
  'notifications.email.placeHolder': 'Введите email',
  'notifications.enterName': 'Введите имя!',
  'notifications.enterNotifyText': 'Введите текст уведомления!',
  'notifications.fill': 'Заправка',
  'notifications.final_fuel': 'Конечное топливо',
  'notifications.friday': 'Пятница',
  'notifications.from': 'с',
  'notifications.geozone_name': 'Имя геозоны',
  'notifications.geozones.chooseVerficationType': 'Выберите тип проверки!',
  'notifications.geozones.control': 'Контроль геозон',
  'notifications.geozones.geozones': 'Геозоны',
  'notifications.geozones.verificationType': 'Тип проверки',
  'notifications.hs_to_service': 'Количество превышенных моточасов',
  'notifications.km_to_service': 'Количество километров превышенного пробега',
  'notifications.kmph': 'км/ч',
  'notifications.latitude': 'Широта',
  'notifications.longitude': 'Долгота',
  'notifications.lossDuration': 'Длительность потери',
  'notifications.lostMsg': 'Выберите интервал по времени!',
  'notifications.main': 'Основное',
  'notifications.markAsRead': 'Отметить прочитанным',
  'notifications.maxSpeed': 'Скорость не более',
  'notifications.min': 'мин',
  'notifications.monday': 'Понедельник',
  'notifications.more': 'Дополнительно',
  'notifications.motohours': 'По моточасам',
  'notifications.name': 'Название',
  'notifications.no_sound': 'Без звука',
  'notifications.notification': 'Уведомления',
  'notifications.notificationName': 'Название уведомления',
  'notifications.objectName': 'Имя объекта',
  'notifications.objects': 'Объекты',
  'notifications.online': 'Онлайн',
  'notifications.paramTab': 'Табличка параметров:',
  'notifications.period': 'Период',
  'notifications.periodcontrol': 'Период контроля относительно текущего времени',
  'notifications.phone.placeHolder': 'Введите телефон...',
  'notifications.pointName': 'Название точки',
  'notifications.push.placeHolder': 'Введите имя или email',
  'notifications.responsetime': 'Мин. время сработки уведомления',
  'notifications.saturday': 'Суббота',
  'notifications.sensor.control': 'Контроль значения датчика',
  'notifications.sensor.controleGeo': 'Контроль геозон',
  'notifications.sensor.maskByName': 'Маска по имени',
  'notifications.sensor.meaning': 'Значение',
  'notifications.sensor.meaningFrom': 'Значение от',
  'notifications.sensor.meaningTo': 'Значение до',
  'notifications.sensor.minimalTime': 'Мин. время срабатывания:',
  'notifications.sensor.selectFrame': 'Выберите рамки!',
  'notifications.sensor.type': 'Тип датчика',
  'notifications.sensor.warning': 'Выберите тип датчика или маску по имени!',
  'notifications.sensor.warningFromTo': 'Выберите значение от или до!',
  'notifications.sensor.work': 'Срабатывать',
  'notifications.sensor.writeNumber': 'Введите число',
  'notifications.sensorValue': 'Значение датчика',
  'notifications.sensors': 'Датчики',
  'notifications.service.byMilage': 'По пробегу',
  'notifications.service.days': 'дней',
  'notifications.service.for': 'За',
  'notifications.service.km': 'км',
  'notifications.service.maintenance': 'Уведомлять о приближении срока планового ТО',
  'notifications.service.warning': 'Введите интервал по дням, пробегу или моточасам!',
  'notifications.simpleTransport': 'Простой транспортного средства',
  'notifications.speed': 'Скорость',
  'notifications.sunday': 'Воскресенье',
  'notifications.telegram.placeHolder': 'Введите имя или telegram',
  'notifications.template': 'Шаблон уведомлений',
  'notifications.temporaryValueMonitoring': 'Временный контроль значений',
  'notifications.text': 'Текст',
  'notifications.text.arrival': 'Уведомляем о приближении или просрочке планового техобслуживания у объекта %UNIT%',
  'notifications.text.confirmation': 'Пользователи не подтвердившие e-mail получат письмо с предложением сделать это. После подтверждения они начнут получать e-mail рассылки.',
  'notifications.text.connection': 'Зафиксирована потеря связи или координат %UNIT% в %TIME% возле %ADDRESS%',
  'notifications.text.drain': '%UNIT% в %TIME% слил %DRAIN% до %LAST_FUEL% возле %ADDRESS%',
  'notifications.text.events_routes': 'Cобытие рейса',
  'notifications.text.exit': '%UNIT% в %TIME% зафиксирован вход в геозону / выход из геозоны на скорости %SPEED% возле %ADDRESS%',
  'notifications.text.filling': '%UNIT% в %TIME% заправился на %FILL% до %LAST_FUEL% возле %ADDRESS%',
  'notifications.text.geozone.inside': '%UNIT% в %TIME% зафиксирован вход в геозону %GEOZONE% на скорости %SPEED% возле %ADDRESS%',
  'notifications.text.geozone.outside': '%UNIT% в %TIME% зафиксирован выход из геозоны %GEOZONE% на скорости %SPEED% возле %ADDRESS%',
  'notifications.text.idle': 'Объект %UNIT% в %TIME% превысил допустимое время простоя возле %ADDRESS%',
  'notifications.text.sensor': '%UNIT% в %TIME% зафиксировано включение датчика на скорости %SPEED% возле %ADDRESS%',
  'notifications.text.speed': '%UNIT% в %TIME% превысил максимально допустимый предел скорости со значением %SPEED% возле %ADDRESS%',
  'notifications.text.trip': '%UNIT% в %TIME% зафиксировано событие рейса возле %ADDRESS%',
  'notifications.textDescription': 'Введите текст уведомления, используя специальные параметры, приведеные ниже. Когда уведомление сработает, эти параметры будут заменены реальными значениями.',
  'notifications.thursday': 'Четверг',
  'notifications.time': 'Время сработки',
  'notifications.to': 'до',
  'notifications.tripName': 'Название рейса',
  'notifications.trips.alertEvent': 'События оповещения:',
  'notifications.trips.arrCheckPoint': 'Прибытие в контрольные точки',
  'notifications.trips.departure': 'Отправление из контрольных точек',
  'notifications.trips.end': 'Конец рейса',
  'notifications.trips.mask': 'Маска имени рейса',
  'notifications.trips.onlyViolation': 'Только в случае нарушения:',
  'notifications.trips.pass': 'Пропуск контрольных точек (Строгий порядок)',
  'notifications.trips.start': 'Начало рейса',
  'notifications.trips.validationOne': 'Правильно введите названия сервиса!',
  'notifications.tuesday': 'Вторник',
  'notifications.types': 'Типы уведомлений',
  'notifications.use_speed_limit': 'Ограничить возможность разгона свыше',
  'notifications.velocity.geozones': 'Геозоны',
  'notifications.velocity.max': 'Не более',
  'notifications.velocity.min': 'Не менее',
  'notifications.velocity.speed': 'Контроль скорости',
  'notifications.velocity.warning': 'Введите скорость не более или не менее!',
  'notifications.view': 'Вид',
  'notifications.warning': 'введите минимальную и максимальную скорость',
  'notifications.wednesday': 'Среда',
  'notifications.workType': 'Тип работ',
  'notifications.service.controlService': 'Контроль сервиса',
  'notifications.success.title': 'Успех',
  'notifications.success.message': 'Уведомление успешно добавлено',
  'notifications.success.message2': 'Уведомление успешно обновлено',
  'notifications.one.object': 'Объект',
  'notifications.more.object': 'Объекта',
  'notifications.many.object': 'Объектов',
  'notifications.commands.warning': 'Требуется указать минимум одну команду для одного из протоколов.',
  'notifications.commands.select_template': 'Применить шаблон',
  'notifications.commands.send_command': 'Отправка команд',
  'notifications.commands.template_empty': 'Шаблоны не найдены',
  'objecrRecoveryTranslater.copyObject': 'Копирование объекта',
  'objecrRecoveryTranslater.objectCompany': 'объект в компании',
  'objecrRecoveryTranslater.restore': 'Вы действительно хотите восстановить?',
  'objecrRecoveryTranslater.restoring': 'Восстановить',
  'objecrRecoveryTranslater.success': 'объект успешно восстановлен',
  object: 'Объект',
  objects: 'Объект',
  'objects.add.objects': 'Добавить объекты',
  'objects.add.objects.in.group': 'Добавить объекты в группу',
  'objects.add.units.to.group': 'Добавить объекты в группу',
  'objects.create.edit.group': 'Редактирование группы',
  'objects.create.new.group': 'Создание группы',
  'objects.delete': 'Удаление',
  'objects.device': 'Тип устройства',
  'objects.editImei': 'Редактировать выбранный IMEI',
  'objects.fillfield': 'Необходимо заполнить поля в разделе Основные',
  'objects.groups': 'Группы',
  'objects.groups.access': 'Права доступа к группе',
  'objects.groups.access-title': 'Права на группу',
  'objects.groups.acess': 'Доступ',
  'objects.groups.close-all': 'Свернуть все',
  'objects.groups.objects-in-group': 'Объекты в группе',
  'objects.imei': 'IMEI',
  'objects.locator.access-object': 'Объект доступа',
  'objects.locator.copy-link': 'Ссылка скопирована',
  'objects.locator.status': 'Статус',
  'objects.locators': 'Ссылки доступа',
  'objects.locators-date-end': 'Дата и время завершения доступа',
  'objects.locators-date-select': 'Выберите дату',
  'objects.locators-date-start': 'Дата и время начала доступа',
  'objects.locators-link': 'Ссылка',
  'objects.name': 'Введите имя!',
  'objects.name.group': 'Название группы',
  'objects.name.number': 'Имя/Номер',
  'objects.objects': 'Объекты',
  'objects.showmonitoring': 'Показать в Мониторинге',
  'objects.title': 'Справочник объектов',
  'objects.titleLocators': 'Справочник ссылок доступа',
  'objects.titleTrailers': 'Справочник прицепов',
  'objects.titlegroups': 'Справочник групп',
  'objects.total.group': 'Количество объектов',
  'objects.trailers': 'Прицепы',
  'objects.type': 'Тип объекта',
  'objects.unit': 'Единица измерения',
  'objects.users': 'Пользователи',
  'objectsSensors.cansel': 'Отменить',
  'objectsSensors.clearSelect': 'Очистить выбор',
  'objectsSensors.copy': 'Скопировать',
  'objectsSensors.copySensors': 'Копировать датчики',
  'objectsSensors.noData': 'Нет данных',
  'objectsSensors.copy.success': 'Датчики успешно скопированы',
  'objectsSensors.copy.error': 'Не выбраны датчики или объекты',
  'objectsSensors.copy.text1': 'Новые датчики добавляются к старым. Если у датчиков совпадают имена, то сохраняются только новые.',
  'objectsSensors.copy.text2': 'Все датчики объекта будут удалены, а после добавлены копируемые датчики.',
  'objectsSensors.copy.text3': 'Новые датчики добавляются к старым. Если среди добавляемых датчиков есть названия дублирующие названия датчиков самого объект, то к названиям копируемых датчиков добавляется цифра (начиная с единицы).',
  objects_locator_change: 'Редактирование ссылки',
  objects_locator_create: 'Создание ссылки доступа',
  objects_noPicture: 'Изображение не выбрано',
  objects_phone1: 'Телефон 1',
  objects_phone2: 'Телефон 2',
  objects_port: 'Порт:',
  objects_server: 'Сервер получения данных:',
  'objects_tabs.TO': 'ТО',
  'objects_tabs.drivers.add': 'Добавить водителя',
  'objects_tabs.drivers.change': 'Изменить водителя',
  'objects_tabs.drivers.driver': 'Водитель',
  'objects_tabs.drivers.from': 'С',
  'objects_tabs.drivers.to': 'По',
  'objects_tabs.extra': 'Дополнительные',
  'objects_tabs.extra.addNewField': 'Добавить новое поле',
  'objects_tabs.extra.changeField': 'Изменить поле',
  'objects_tabs.extra.custom': 'Пользовательские',
  'objects_tabs.extra.etc': 'Дополнительно',
  'objects_tabs.extra.fuel': 'Топливо',
  'objects_tabs.extra.motohours': 'Моточасы',
  'objects_tabs.extra.name': 'Имя',
  'objects_tabs.extra.trips': 'Поездки',
  'objects_tabs.extra.update_field': 'Обновить поле',
  'objects_tabs.extra.value': 'Значение',
  'objects_tabs.main': 'Основные',
  'objects_tabs.main.analyst': 'Аналитик',
  'objects_tabs.main.analyst.error': 'Выберите аналитика',
  'objects_tabs.main.device.error': 'Выберите тип устройства',
  'objects_tabs.main.id.error': 'Выберите уникальный ID',
  'objects_tabs.main.name.error': 'Введите Имя/Номер',
  'objects_tabs.main.phone.error': 'Введите телефон',
  'objects_tabs.main.type.error': 'Выберите тип объекта',
  'objects_tabs.sensor': 'Датчики',
  'objects_tabs.sensors.actions': 'Действия',
  'objects_tabs.sensors.border_bottom': 'Нижняя граница',
  'objects_tabs.sensors.coefficient': 'Коэффициент',
  'objects_tabs.sensors.coefficientA': 'Коэффициент А',
  'objects_tabs.sensors.coefficientB': 'Коэффициент Б',
  'objects_tabs.sensors.description': 'Описание',
  'objects_tabs.sensors.enterValue': 'Введите значение!',
  'objects_tabs.sensors.error_formula': 'Выберите формулу!',
  'objects_tabs.sensors.error_param12': 'Выберите параметр 1 или параметр 2!',
  'objects_tabs.sensors.error_type_sensor': 'Введите тип датчика!',
  'objects_tabs.sensors.formula': 'Формула',
  'objects_tabs.sensors.formula.columns': 'Формула колонки',
  'objects_tabs.sensors.hide_in_monitoring': 'Скрывать в мониторинге',
  'objects_tabs.sensors.hide_in_reports': 'Скрывать в отчетах',
  'objects_tabs.sensors.keyExist': 'Такой ключ уже существует!',
  'objects_tabs.sensors.lowerBorder': 'Нижняя граница X',
  'objects_tabs.sensors.option1': 'Параметр 1',
  'objects_tabs.sensors.option2': 'Параметр 2',
  'objects_tabs.sensors.selectA': 'Выберите значение a!',
  'objects_tabs.sensors.selectB': 'Выберите значение b!',
  'objects_tabs.sensors.selectFromValue': 'Выберите fromValue!',
  'objects_tabs.sensors.selectValueX': 'Выберите значение x!',
  'objects_tabs.sensors.selectValueY': 'Выберите значение !',
  'objects_tabs.sensors.sensors_create': 'Создание датчика',
  'objects_tabs.sensors.sensors_edit': 'Редактирование датчика',
  'objects_tabs.sensors.show_graph': 'Показать график',
  'objects_tabs.sensors.show_table': 'Показать таблицу',
  'objects_tabs.sensors.smoothing': 'Сглаживание',
  'objects_tabs.sensors.type_sensor': 'Тип датчика',
  'objects_tabs.sensors.units': 'Единица измерения',
  'objects_tabs.sensors.upperBorder': 'Верхняя граница X',
  'objects_tabs.sensors.valueX': 'Значение X',
  'objects_tabs.sensors.valueY': 'Значение Y',
  'objects_tabs.services.active_on_interval_days': 'Активировать интервал в днях',
  'objects_tabs.services.active_on_interval_mileage': 'Активировать интервал по пробегу',
  'objects_tabs.services.create_form': 'Создание ТО',
  'objects_tabs.services.date.placeholder': 'Введите дату!',
  'objects_tabs.services.description': 'Описание',
  'objects_tabs.services.edit_form': 'Редактирование ТО',
  'objects_tabs.services.intervalInDays': 'Введите интервал в днях!',
  'objects_tabs.services.intervalInMotoHours': 'Активировать интервал по моточасам',
  'objects_tabs.services.intervalInMotoHoursDate': 'Интервал по моточасам, число/часы',
  'objects_tabs.services.interval_days': 'Дни',
  'objects_tabs.services.interval_days_numb_day': 'Интервал в днях, число/дни',
  'objects_tabs.services.interval_mileage': 'Пробег',
  'objects_tabs.services.interval_mileage_numb_km': 'Интервал по пробегу, число/км',
  'objects_tabs.services.lastTime': 'Введите промежуток пробега',
  'objects_tabs.services.lastTimeDate': 'Последний раз, число/часы',
  'objects_tabs.services.lastTimeDate.warning': 'Введите последний раз!',
  'objects_tabs.services.last_one_date': 'Последний раз, дата',
  'objects_tabs.services.last_one_numb_km': 'Последний раз, число/км',
  'objects_tabs.services.mileageInterval': 'Введите текущее показание пробега',
  'objects_tabs.services.name_service': 'Cервис',
  'objects_tabs.services.warning.date': 'Введите дату!',
  'objects_tabs.services.warning.description': 'Введите описание!',
  'objects_tabs.services.warning.name': 'Введите названия сервиса!',
  'objects_tabs.trailer': 'Прицеп',
  'objects_tabs.trailer.add': '"Добавить прицеп',
  'objects_tabs.trailer.change': 'Изменить прицеп',
  'objects_tabs.trailers': 'Прицепы',
  objects_trailer_change: 'Изменить прицеп',
  objects_trailer_create: 'Создать прицеп',
  objects_trailer_key: 'Ключ',
  objects_trailer_moreFields: 'Дополнительные поля',
  one_element_deleted: '1 элемент удален',
  'objects.generate.imei': 'Сгенерировать виртуальный IMEI',
  outdated_version: 'Версия устарела',
  outdated_version_message: 'Обновите страницу',
  pdf: 'PDF',
  phone: 'Телефон',
  phone_confirmation: 'Подтверждение телефона',
  'pixels.group': 'точек экрана',
  'qrAdminTranslater.addGps': 'Добавление GPS-трекера',
  'qrAdminTranslater.print': 'Печать',
  'qrAdminTranslater.qrCreate': 'QR-код создания',
  'qrAdminTranslater.register': 'Регистрация и',
  'qrAdminTranslater.scanCode': `Отсканируйте код для регистрации GPS-трекера или введите код по ссылке ${currentUrl}/c`,
  'report.tables.tabs.add_mask': 'Добавить маску по названию датчиков',
  'report.tables.tabs.add_parameter': 'Добавить параметр',
  'report.tables.tabs.enter_value': 'Введите значение',
  'report.tables.tabs.name_mask': 'Маска названия датчиков',
  'report.tables.tabs.parameter': 'Параметр',
  'report.tables.tabs.parameters': 'Параметры',
  'report.tables.tabs/parameter_hint': 'Выберите названия параметров, которые будут участвовать при формировании отчета.',
  'reportEdit.sensor.addTotalValue': 'Добавить итоговое значение в отчете:',
  'reportEdit.sensor.hintText': 'Добавить результирующее значение в строке итогов по данному столбцу в сформированном отчете.',
  reports: 'Отчеты',
  'reports,tables.fields.mask_name_sensor': 'Маска названия датчиков топлива',
  'reports.action': 'Действия',
  'reports.add-group-report': 'Добавить в шаблон',
  'reports.add-in-template': 'Добавить в шаблон',
  'reports.add-new-template': 'Добавить новый шаблон',
  'reports.add-table-to-reports': 'Добавить таблицу из шаблона',
  'reports.applied_filter': 'Применена фильтрация из Мониторинга.',
  'reports.attached': 'Файл прикреплен',
  'reports.base-table': 'Базовая таблица',
  'reports.build-report': 'Построить',
  'reports.chart.fillings': 'Заправки',
  'reports.chart.fillings_markers': 'Интервалы заправок',
  'reports.chart.intervals': 'Интервалы',
  'reports.chart.thefts': 'Сливы',
  'reports.chart.thefts_markers': 'Интервалы сливов',
  'reports.column': 'Колонка',
  'reports.composite_report': 'Составной отчет',
  'reports.connect': 'Связать с графиком',
  'reports.contains': 'содержит',
  'reports.copy': 'Копировать',
  'reports.copy_key': 'Скопируйте ключ в ячейку Excel-шаблона, а затем загрузите шаблон в SKIF.PRO',
  'reports.create_template': 'Создать шаблон',
  'reports.deleted_intervals_messages': 'Удаленные интервалы сооб-ний',
  'reports.deleted_messaged': 'Удаленные сообщения',
  'reports.display_all_events': 'Отобразить все события',
  'reports.download_selected': 'Загрузить выбранные',
  'reports.edit-report': 'Редактировать',
  'reports.edit-reports': 'Редактирование параметров отчета',
  'reports.editTemplate.nameTemplate': 'Название шаблона',
  'reports.edit_template': 'Изменить шаблон',
  'reports.enter_name': 'Введите имя!',
  'reports.exclude_interval': 'Исключить интервал',
  'reports.form_for_unloading': 'Форма для выгрузки (Шаблон excel)',
  'reports.from_monitoring': 'из Мониторинга',
  'reports.group-object': 'Группа объектов',
  'reports.group-table': 'Группа таблиц',
  'reports.increase': 'Увеличить',
  'reports.interval': 'Интервал',
  'reports.interval_for_deletion': 'Интервал для удаления сообщений',
  'reports.interval_info': 'Удалённые интервалы сообщений не отображаются в журнале и не участвуют в формировании отчетов.',
  'reports.is-already-template': 'Существующий',
  'reports.key_hint': 'Создайте шаблон отчета в Excel, затем поместите в него данные скопировав соответствующие ключи. Сохраните шаблон и загрузите в SKIF.PRO, система сама найдёт и подставит значения вместо ключей в момент формирования отчета.',
  'reports.link_to_magazine': 'Связать с журналом',
  'reports.list_key': 'Список ключей для шаблона Excel',
  'reports.load-report': 'Скачать',
  'reports.map_note': 'Щелкните, чтобы увидеть точку на карте',
  'reports.map_snapshot': 'Снимок карты',
  'reports.menu': 'Меню',
  'reports.mini_hint': 'После копирования выберите ячейку Excel-шаблона и нажмите CTRL+V.',
  'reports.moving': 'Перемещение',
  'reports.name-template': 'Имя шаблона',
  'reports.new-temlate': 'Новый',
  'reports.not-select': 'Не выбрано',
  'reports.not_all_objects_displayed': 'Отображаются не все объекты.',
  'reports.object': 'Объект',
  'reports.onlyResult': 'Показать только итоги',
  'reports.operation': 'Операция',
  'reports.period': 'Период',
  'reports.print-report': 'Печать',
  'reports.reduce': 'Уменьшить',
  'reports.reports-size-screen.maximum': 'На весь экран',
  'reports.reports-size-screen.medium': '1/2',
  'reports.reports-size-screen.minimum': '1/4',
  'reports.reports-size-screen.thirdScreen': '1/3',
  'reports.reports-template': 'Редактирование шаблона',
  'reports.have-period-from-report': 'Применён период из отчета. Вы можете изменить его.',
  'reports.today': 'Сегодня',
  'reports.yesterday': 'Вчера',
  'reports.week': 'Эта неделя',
  'reports.month': 'Этот месяц',
  'reports.reports-visit-geozone': 'Отчет по посещению геозон',
  'reports.reset_magnification': 'Сбросить увеличение',
  'reports.select': 'Выбор',
  'reports.select-group-object': 'Выбор группы объектов',
  'reports.select-object': 'Выбор транспортного средства',
  'reports.select-table': 'Выбор таблицы',
  'reports.select-template': 'Выбор шаблона таблиц',
  'reports.select_object': 'Выберите объект!',
  'reports.select_objects_group': 'Выберите группу объектов!',
  'reports.select_with_magnification': 'Выбор с увеличением',
  'reports.sensor_placeholder': 'Маска датчика',
  'reports.show-chart': 'График',
  'reports.show-color': 'Цвет трека',
  'reports.show_all_objects': 'Показать все объекты',
  'reports.show_annotation_on_click': 'Аннотация по клику на строку',
  'reports.show_annotation_on_click_hint': 'Если активировать, то при при клике на строку отчета отображается аннотация по событию.',
  'reports.show_key': 'Смотреть список ключей для Excel-шаблона',
  'reports.success_copy_template': 'Шаблон успешно скопирован.',
  'reports.tab-columns': 'Колонки',
  'reports.tab-geozones': 'Геозоны',
  'reports.tab-main': 'Общее',
  'reports.tab-main.group': 'Группировка',
  'reports.tab-main.group-field': 'Группировка по полю',
  'reports.tab-main.interval-priority': 'Приоритет интервалов отчета',
  'reports.tab-main.interval-priority-tooltip': 'Если эта настройка активна и интервал разрезается началом или концом Смены, то он в отчет попадает полностью.<br>Если же эта настройка не активна, то в отчет попадет точный интервал Смены.<br>Например если смена начинается 08:00, а поездка началась в 06:00,<br>то в случае активной настройки “Приоритет интервалов отчета”, интервал в отчете Поездки начнется в 06:00,<br>а если настройка “Приоритет интервалов отчета” не активна - то в 08:00.',
  'reports.tab-main.show-total': 'Показывать итог',
  'reports.table.type': 'Тип Таблицы',
  'reports.tables': 'Таблицы',
  'reports.tables.add_mask_sensor': 'Добавить маску по названию датчиков',
  'reports.tables.columns.fields.column': 'Колонка',
  'reports.tables.columns.fields.filter': 'Фильтровать',
  'reports.tables.columns.fields.max_value': 'Макс. значение',
  'reports.tables.columns.fields.min_value': 'Мин. значение',
  'reports.tables.columns.fields.name.error': 'Введите название',
  'reports.tables.columns.fields.name.label': 'Имя колонки',
  'reports.tables.columns.fields.visibility': 'Видимость',
  'reports.tables.fields.add_mask': 'Добавить маску по названию датчиков',
  'reports.tables.fields.excel_template_name': 'Имя Шаблона Excel',
  'reports.tables.fields.files_warn': 'Можно загружать файлы только в форматах xlsx xlsm xlsb',
  'reports.tables.fields.geozone_warn': 'Требуется выбрать хотя бы одну геозону входа и одну геозону выхода.',
  'reports.tables.fields.grouping': 'Группировка',
  'reports.tables.fields.grouping_by_field': 'Группировка по полю',
  'reports.tables.fields.name.error': 'Введите имя!',
  'reports.tables.fields.name.label': 'Имя',
  'reports.tables.fields.sensor_name_mask': 'Маска названия датчиков',
  'reports.tables.fields.showThefts': 'Отображать заправки',
  'reports.tables.fields.showfillings': 'Отображать сливы',
  'reports.tables.fields.template_upload': 'Загрузить',
  'reports.tables.geozones.enter': 'Вход',
  'reports.tables.geozones.exit': 'Выход',
  'reports.tables.geozones.title': 'Геозоны',
  'reports.tables.report': 'Отчет',
  'reports.tables.tabs.additional_columns': 'Доп. колонки',
  'reports.tables.tabs.all_protocol': 'Все протоколы',
  'reports.tables.tabs.columns': 'Колонки',
  'reports.tables.tabs.data': 'Данные',
  'reports.tables.tabs.general': 'Общее',
  'reports.tables.tabs.geozones': 'Геозоны',
  'reports.tables.tabs.hint': 'В закрытых отчетах пользователь не может изменить датчики и параметры по которым он строится.',
  'reports.tables.tabs.make_close_report': 'Сделать отчет закрытым',
  'reports.tables.tabs.mask_sensor': 'Маски датчиков',
  'reports.tables.tabs.mask_sensor_hint': 'Для формирования отчета выбираются те датчики объекта, которые будут удовлетворять хотя бы одной из указанных масок (читайте подробнее на',
  'reports.tables.tabs.parameters': 'Выберите параметр',
  'reports.tables.tabs.sensors': 'Датчики',
  'reports.tables.tabs.table_name': 'Название:',
  'reports.tables.tabs.type_table': 'Тип таблицы:',
  'reports.template': 'Шаблон',
  'reports.templateWarning': 'Введите имя шаблона!',
  'reports.template_deletion': 'Удаление шаблона',
  'reports.template_deletion_sure': 'Вы действительно хотите удалить шаблон отчета',
  'reports.value': 'Значение',
  'reports.was-deleted': 'был удален',
  'reports.without_grouping': 'Без группировки',
  'reportsFilter.addInterval': 'Добавить интервал',
  'reportsFilter.daysWeek': 'Дни недели:',
  'reportsFilter.filterByTime': 'Фильтрация по интервалам времени:',
  'reportsFilter.filterByGeozone': 'Фильтрация по геозонам',
  'reportsFilter.hint.in': 'Внутри геозон',
  'reportsFilter.hint.out': 'Вне геозон',
  'reportsFilter.hint.in.text': 'в отчете отображаются только те данные которые были получены в выбранных геозонах.',
  'reportsFilter.hint.out.text': 'в отчете отображаются только те данные которые были получены за пределами выбранных геозон.',
  'reportsFilter.filterType': 'Тип фильтрации:',
  'reportsFilter.filterHintFunction': 'Если функция включена, то состояние (например, поездка), начавшееся внутри интервала, но завершившееся за его пределами, будет обрезано. Только продолжительность внутри указанного интервала будет учтена в отчёте, остальное будет отброшено.',
  'reportsFilter.filterHintSelectInterval': 'Выберите временные интервалы по которым будут отфильтрованы данные в отчете.',
  'reportsFilter.title': 'Фильтрация',
  'reportsFilter.trimmerInterval': 'Обрезать интервалы:',
  'reportsFilter.zeroInterval': 'Нулевой интервал!',
  'reportsFilter.groupBy': 'Группировки',
  'reportsFilter.addGroup': 'Добавить группировку',
  'reportsFilter.byGroup.tooltip': 'На данный момент можно добавить не более трёх одновременных группировок',
  'reportsFilter.byGroup.noSearch.msg': 'Группировки не найдены',
  'reportsTemplate.successUpdate.msg': 'Шаблон успешно сохранен',
  'reports.loadingMoreData.msg': 'Получение данных',

  'reportsTranslater.noData': 'Нет данных для построения отчета',
  'reportsTranslater.rewrite': 'Перезапросить',
  'response-terminal': 'Ответ',
  'retranslatorTranlsater.changeIMEI': 'Заменить на IMEI',
  'retranslatorTranlsater.gps_signal_category': 'Категория GPS-сигнала:',
  'retranslatorTranlsater.hint': 'Медленный используется для трекеров общественного транспорта',
  'retranslatorTranlsater.participant': 'Идентификатор участника программы:',
  'retranslatorTranlsater.port': 'Порт ретрасляции',
  'retranslatorTranlsater.protocol': 'Протокол',
  'retranslatorTranlsater.retranslationFrom': 'Ретраслировать с',
  'retranslatorTranlsater.retranslationTo': 'Ретраслировать до',
  'retranslatorTranlsater.route_identifier': 'Идентификатор маршрута:',
  'retranslatorTranlsater.serverRetranslation': 'Сервер ретрансляции',
  'retranslatorTranlsater.type_vehicle': 'Тип общественного транспортного средства:',
  role: 'Роль',
  'routesTrips.addTrip': 'Добавить рейс',
  'routesTrips.editTrip': 'Редактировать рейс',
  'routesTrips.name': 'Имя',
  'routesTrips.route.addFromGeozones': 'Добавить геозоны',
  'routesTrips.route.addFromGeozones.title': 'Добавить геозоны',
  'routesTrips.route.addFromMap': 'Добавить точку с карты',
  'routesTrips.route.create': 'Создать маршрут',
  'routesTrips.route.createGeozone.figure': 'Фигура',
  'routesTrips.route.createGeozone.name': 'Название',
  'routesTrips.route.createGeozone.title': 'Добавить точку с карты',
  'routesTrips.route.createGeozone.width': 'Ширина',
  'routesTrips.route.edit': 'Редактировать маршрут',
  'routesTrips.route.name.placeholder': 'Имя маршрута',
  'routesTrips.route.point': 'Точка',
  'routesTrips.routes': 'Маршруты',
  'routesTrips.title': 'Маршруты и рейсы',
  'routesTrips.trip.activationType': 'Тип активации',
  'routesTrips.trip.activationType.tooltip': 'Если выбрано “по времени первой точки” то начало рейса фиксируется строго по времени указанных в первой контрольной точке. Если выбрано “по входу в первую точку” то начало рейса фиксируется по времени входа в область первой точки, а все последующие времена сдвигаются на время разницы между временем “Прибытие” указанным в первой контрольной точке и временем входа в область первой точки. Если выбрано “по выходу из первой точки” то начало рейста фиксируется по времени выхода из области первой точки, а все последующие контрольные времена сдвигаются на время разницы времени “Отправления” указанном в первой контрольной точке и временем выхода из области первой точки.',
  'routesTrips.trip.addDays': 'Добавить сутки к последующим точкам',
  'routesTrips.trip.addFromGeozones': 'Добавить геозоны',
  'routesTrips.trip.addFromGeozones.title': 'Добавить геозоны',
  'routesTrips.trip.addFromMap': 'Добавить точку с карты',
  'routesTrips.trip.addFromRoutes': 'Добавить точки маршрута',
  'routesTrips.trip.addFromRoutes.title': 'ДОБАВИТЬ ТОЧКИ МАРШРУТА',
  'routesTrips.trip.arrival': 'Прибытие',
  'routesTrips.trip.arrivalDev': 'Отклонение прибытия',
  'routesTrips.trip.clearDays': 'Очистить доп. сутки',
  'routesTrips.trip.createGeozone.figure': 'Фигура',
  'routesTrips.trip.createGeozone.name': 'Название',
  'routesTrips.trip.createGeozone.title': 'Добавить точку с карты',
  'routesTrips.trip.date.cancel_days_period': 'Исключить праздничные дни',
  'routesTrips.trip.date.filter_days': 'Отфильтровать дни',
  'routesTrips.trip.date.placeholder': 'Выберите даты',
  'routesTrips.trip.date.reset_days_period': 'Восстановить дни периода',
  'routesTrips.trip.date.set_days_period': 'Отфильтровать дни периода',
  'routesTrips.trip.date.total_days_period': 'Итоговые дни периода',
  'routesTrips.trip.date.week_days.all_days': 'Все дни',
  'routesTrips.trip.date.week_days.even': 'Четные',
  'routesTrips.trip.date.week_days.friday': 'ПТ',
  'routesTrips.trip.date.week_days.monday': 'ПН',
  'routesTrips.trip.date.week_days.odd': 'Не четные',
  'routesTrips.trip.date.week_days.saturday': 'СБ',
  'routesTrips.trip.date.week_days.sunday': 'ВС',
  'routesTrips.trip.date.week_days.thursday': 'ЧТ',
  'routesTrips.trip.date.week_days.tuesday': 'ВТ',
  'routesTrips.trip.date.week_days.wednesday': 'СР',
  'routesTrips.trip.departure': 'Отправление',
  'routesTrips.trip.departureDev': 'Отклонение отправления',
  'routesTrips.trip.driver': 'Водитель',
  'routesTrips.trip.editGeozone.figure': 'Фигура',
  'routesTrips.trip.editGeozone.name': 'Название',
  'routesTrips.trip.editGeozone.title': 'Редактирование геозоны',
  'routesTrips.trip.entranceToFirstPoint': 'По входу в первую точку',
  'routesTrips.trip.firstPoint': 'По времени первой точки',
  'routesTrips.trip.leavingToFirstPoint': 'По выходу из первой точки',
  'routesTrips.trip.name': 'Имя*',
  'routesTrips.trip.name.placeholder': 'Имя рейса...',
  'routesTrips.trip.object': 'Объект*',
  'routesTrips.trip.object.placeholder': 'Объект',
  'routesTrips.trip.order': 'Порядок прохождения',
  'routesTrips.trip.order.arbitrary': 'Произвольный',
  'routesTrips.trip.order.strict': 'Строгий',
  'routesTrips.trip.period': 'Период',
  'routesTrips.trip.point': 'Точка',
  'routesTrips.trip.pointsValidation': 'Выберите хотя бы две точки',
  'routesTrips.trip.removeDays': 'Удалить сутки к последующим точкам',
  'routesTrips.trip.trailer': 'Прицеп',
  'routesTrips.trip.validation': 'Поле обязательно для заполнения',
  'routesTrips.trip.validation.total_period': 'Требуется добавить как минимум один итоговый день',
  'routesTrips.trip.workType': 'Тип работ',
  'routesTrips.trip.work_type_one': 'Тип работ 1',
  'routesTrips.trip.work_type_three': 'Тип работ 3',
  'routesTrips.trip.work_type_two': 'Тип работ 2',
  'routesTrips.trips': 'Рейсы',
  save: 'Сохранить',
  search: 'Поиск',
  search_company: 'Поиск по объектам',
  select: 'Выбрать',
  select_all: 'Выбрать все',
  send: 'Отправить',
  'send-terminal': 'Отправка',
  'sensorNotificationTranslater.inFrame': 'В установленных рамках',
  'sensorNotificationTranslater.outFrame': 'Вне установленных рамок',
  'sensors_details.choose': 'Выбрать',
  'sensors_details.help_validator': 'Валидатора поможет отфильтровать или преобразовать значения датчика',
  'sensors_details.sensor_validator': 'Датчик валидатор:',
  'sensors_details.validation_info': 'В мониторинге не будет отображаться значение датчика если валидных данных не было более 3 суток.',
  'sensors_details.validation_principle': 'Принцип валидации:',
  'sessions.day': 'Сутки',
  'sessions.event': 'Событие',
  'sessions.ip': 'IP-адрес',
  'sessions.month': 'Месяц',
  'sessions.time': 'Дата/Время',
  'sessions.title': 'Сессии',
  'sessions.user': 'Пользователь',
  'sessions.week': 'Неделя',
  'setting-company': 'Настройки компании',
  'setting-user': 'Настройки пользователя',
  'settings.balance.company': 'Компания:',
  'settings.balance.currency': 'руб',
  'settings.balance.errors.amount': 'Введите сумму пополнения',
  'settings.balance.min_amount': 'Минимальная сумма платежа',
  'settings.balance.mir_unavailable': 'Карты МИР временно не доступны для сохранения',
  'settings.balance.save_card': 'Сохранить карту',
  'settings.balance.top_up': 'Пополнить',
  'settings.balance.top_up_amount': 'Сумма пополнения',
  'settings.balance.top_up_title': 'Пополнение',
  'settings.balance.turn_on_autopay': 'Включить автоплатёж',
  'settings.company.data_format': 'Формат даты:',
  'settings.company.exit_time': 'Выход через n минут бездействия',
  'settings.company.exit_time_tooltip': '0 или пустое поле - не будет выхода по бездействию',
  'settings.company.high_security_mode': 'Режим повышенной безопасности',
  'settings.company.high_security_mode_hint.first': 'Для повышения безопасности, при активации этого режима новым пользователям, потребуется создавать надежные пароли. Такой пароль должен содержать не менее 8 символов (для администраторов не менее 15 символов), включать буквы верхнего и нижнего регистра, цифры и специальные символы.',
  'settings.company.high_security_mode_hint.second': 'При смене пароля пользователю, необходимо войти в систему с использованием пароля от администратора, а затем установить собственный пароль для учетной записи. Таким образом, администратору не будет известен пароль от аккаунта пользователя.',
  'settings.company.integrator': 'Интегратор:',
  'settings.company.last_widget_time': 'Время последней даты в виджете:',
  'settings.company.name': 'Название:',
  'settings.company.note': 'Укажите какой формат времени будет применяться при выборе последней даты в интервале дат (в виджете выбора дат).',
  'settings.company.retransmit_efis': 'Ретранслировать в ЕФИС',
  'settings.company.success.update_company': 'Настройки компании успешно обновлены',
  'settings.company.time_format': 'Формат времени:',
  'settings.company.time_zone': 'Часовой пояс:',
  'settings.company.title': 'Настройки компании',
  'settings.display.area_measurement': 'Единицы измерения площади:',
  'settings.display.geozones': 'ГЕОЗОНЫ',
  'settings.display.group_by_dots': 'Группировать, если расстояние менее (точек экрана):',
  'settings.display.hectar': 'га',
  'settings.display.hide_events': 'Скрыть события на карте:',
  'settings.display.hide_notification': 'Скрыть уведомления на карте:',
  'settings.display.kilometer': 'км',
  'settings.display.map': 'Карта',
  'settings.display.meter': 'м',
  'settings.display.monitoring': 'Мониторинг',
  'settings.display.notification': 'Уведомления',
  'settings.display.object_grouping': 'Группировка объектов на карте:',
  'settings.display.show_annotation': 'Показывать аннотацию при клике на объект в списке мониторинга:',
  'settings.display.show_geozones': 'Показать геозоны на карте:',
  'settings.display.title': 'Настройки отображения',
  'settings.display.track_weight': 'Толщина трека на карте',
  'settings.invite.access_level': 'Уровень доступа:',
  'settings.invite.errors.access_level': 'Выберите уровень доступа',
  'settings.invite.errors.user_email': 'Введите email',
  'settings.invite.submit_button': 'Пригласить',
  'settings.invite.success.invite': 'Пользователь был успешно приглашен',
  'settings.invite.title': 'Пригласить пользователя',
  'settings.invite.user_email': 'Email пользователя:',
  'settings.invite.user_email_placeholder': 'Укажите email',
  'settings.main.access_settings': 'Настройки доступов',
  'settings.main.add_balance': 'Пополнить баланс',
  'settings.main.additional': 'Дополнительно:',
  'settings.main.company': 'Компания:',
  'settings.main.company_settings': 'Настройки компании',
  'settings.main.company_tariff': 'Тариф компании',
  'settings.main.display_settings': 'Настройки отображения',
  'settings.main.exit': 'Выйти',
  'settings.main.finance_and_balance': 'Финансы и баланс:',
  'settings.main.financical_report': 'Финансовый отчет',
  'settings.main.go_admin_panel': 'Перейти в админ. панель',
  'settings.main.go_admin_panel_beta': 'Админ. панель',
  'settings.main.go_back': 'Вернуться',
  'settings.main.invite_user': 'Пригласить пользователя',
  'settings.main.news_and_updates': 'Новости и обновления',
  'settings.main.payments_history': 'История платежей',
  'settings.main.reference': 'Справка (Wiki)',
  'settings.main.sessions': 'Сессии',
  'settings.main.support': 'Поддержка',
  'settings.main.work_with_company': 'Работа с компанией:',
  'settings.support.contacts.email': 'E-mail: monitoringplus@support.ru',
  'settings.support.contacts.phone': 'Телефон (только по будням с 9:00 до 18:00): 89008887712',
  'settings.support.contacts.telegram': 'Telegram: @Monitoring_plus',
  'settings.support.description': 'Добрый день! Сотрудники Мониторинг плюс (Москва), будут рады ответить на ваши вопросы. Чтобы связаться с нами обратитесь по одному из контактов ниже:',
  'settings.support.title': 'Поддержка',
  'settings.tariff.accounting_type': 'Тип учета:',
  'settings.tariff.active_autopay': 'Активирован автоплатёж',
  'settings.tariff.autopay': 'Автоплатёж:',
  'settings.tariff.by_number_objects': 'По числу объектов',
  'settings.tariff.by_number_objects_sent_data': 'По числу объектов присылавших данные',
  'settings.tariff.card': 'Карта:',
  'settings.tariff.card_section.add': 'Добавление карты',
  'settings.tariff.card_section.add_note': 'Для добавления карты автоплатежа, нам потребуется списать у вас 1 рубль. При оплате укажите реквизиты карты.',
  'settings.tariff.card_section.change': 'Смена карты для автоплатежа',
  'settings.tariff.card_section.continue': 'Продолжить',
  'settings.tariff.card_section.errors.mir_error': 'Автоплатёж временно недоступен с картами МИР',
  'settings.tariff.card_section.mir_system': 'Система МИР',
  'settings.tariff.card_section.select_system': '*Выберите платёжную систему:',
  'settings.tariff.card_section.temporarily_unavailable': 'Временно не доступно',
  'settings.tariff.card_section.visa_master': 'Visa или Mastercard',
  'settings.tariff.errors.autopay': 'Функция автоплатежа была отключена, так как на карте недостаточно средств для совершения операции.',
  'settings.tariff.last_period_sum': 'Сумма за прошлый период',
  'settings.tariff.next_write_off': 'Следующее списание:',
  'settings.tariff.no_card_added': 'Карта не добавлена',
  'settings.tariff.object_count': 'Макс. кол-во объектов:',
  'settings.tariff.object_price': 'Стоимость 1 объекта:',
  'settings.tariff.on_balance': 'На счету (баланс):',
  'settings.tariff.payment_period': 'Период оплаты:',
  'settings.tariff.period_start': 'Начало текущего периода:',
  'settings.tariff.success.delete_card': 'Карта успешна удалена',
  'settings.tariff.success.update_autopay': 'Автоплатеж успешно обновлен',
  'settings.tariff.title': 'Информация по тарифу',
  'settings.tariff.turn_on_autopay': 'Включить автоплатёж',
  'settings.user.access_level': 'Уровень доступа:',
  'settings.user.change_access_level': 'Изменить уровень доступа',
  'settings.user.change_password': 'Изменить пароль',
  'settings.user.code': 'Код:',
  'settings.user.description': 'Описание:',
  'settings.user.edit': 'Редактирование',
  'settings.user.email': 'Email:',
  'settings.user.errors.admin_strong_pass': 'Пароль должен содержать от 15 до 25 символов и состоять как минимум из одной заглавной английской буквы, как минимум одной строчной английской буквы и как минимум одного специального символа (~ ! @ # $ % ^ & * ( ) - = + [ "{ ] " } | ; : " " , < . > / ?)',
  'settings.user.errors.email': 'Введите Email',
  'settings.user.errors.name': 'Введите имя',
  'settings.user.errors.phone_confirmation': 'Телефон не подтверждён.',
  'settings.user.errors.simple_pass': 'Пароль должен состоять от 5 до 25 символов и состоять как минимум из одной английской буквы и как минимум одной цифры',
  'settings.user.errors.user_strong_pass': 'Пароль должен содержать от 8 до 25 символов и состоять как минимум из одной заглавной английской буквы, как минимум одной строчной английской буквы и как минимум одного специального символа (~ ! @ # $ % ^ & * ( ) - = + [ "{ ] " } | ; : " " , < . > / ?)',
  'settings.user.language': 'Язык:',
  'settings.user.mailing_id': 'Id-рассылки для Telegram:',
  'settings.user.name': 'Имя:',
  'settings.user.note.first': '1) Войдите в телеграм-бот:t.me/skif2_bot',
  'settings.user.note.second': '2) Нажмите на Start, бот возвращает ID рассылки для Telegram',
  'settings.user.phone': 'Телефон:',
  'settings.user.set_password': 'Задать пароль',
  'settings.user.success.avatar_change': 'Аватар был успешно обновлен',
  'settings.user.success.change_password': 'Пароль был успешно изменен',
  'settings.user.success.update_info': 'Информация успешно обновлена',
  'settings.user.user': 'Пользователь:',
  'status-terminal': 'Статус',
  success: 'Успех',
  'tariff.accept': 'Согласен',
  'tariff.amount': 'Сумма на счёте',
  'tariff.amounttopay': 'Сумма',
  'tariff.balance': 'На счету:',
  'tariff.blocked-info': 'На счету Компании недостаточно средств. Доступ к компании заблокирован.',
  'tariff.choose': 'Выбор тарифного плана',
  'tariff.choose-pay': 'ВЫБРАТЬ И ОПЛАТИТЬ ТАРИФ',
  'tariff.choose-units': 'Выберите нужное количество объектов:',
  'tariff.company-blocked': 'компания заблокирована',
  'tariff.currency': 'вал',
  'tariff.currency-month': 'валюта/мес',
  'tariff.currency-type': 'валюты',
  'tariff.current': 'Ваш текущий тариф:',
  'tariff.date-time': 'Дата/Время',
  'tariff.days': 'дней',
  'tariff.info-one': 'Стоимость 1 объекта в системе SKIF PRO составляет',
  'tariff.info-two--part-one': 'на',
  'tariff.info-two--part-two': 'календарных дней.',
  'tariff.information': 'ИНФОРМАЦИЯ ПО ТАРИФУ',
  'tariff.kz': 'KZT',
  'tariff.less_than_four_days': 'дня',
  'tariff.next-payment': 'Следующее списание:',
  'tariff.notselected': 'Не выбран',
  'tariff.objects': 'Объекты',
  'tariff.offers': 'оферты.',
  'tariff.one_day': 'день',
  'tariff.operation': 'Операция',
  'tariff.paid': 'Оплаченный период',
  'tariff.pay': 'ОПЛАТИТЬ ТАРИФ',
  'tariff.payableto': 'к оплате:',
  'tariff.payement-date': 'Оплата:',
  'tariff.payment-history': 'ИСТОРИЯ СПИСАНИЙ И ОПЛАТ',
  'tariff.payments-history': 'История платежей',
  'tariff.price': 'Стоимость тарифа:',
  'tariff.remaining': 'Осталось:',
  'tariff.rouble': 'руб',
  'tariff.tariffprice': 'Стоимость тарифа:',
  'tariff.trial': 'Ознакомительный период:',
  'tariff.txt-five': 'необходимо обратиться в поддержку.',
  'tariff.txt-four': 'Если Вы захотите уменьшить тариф, Вам будет',
  'tariff.txt-one': 'Использование системы мониторинга SKIF PRO является платным.',
  'tariff.txt-seven': 'тарифом, правилами оплаты и',
  'tariff.txt-six': 'Нажимая кнопку “Согласен” вы соглашаетесь с',
  'tariff.txt-three': 'Первые 7 дней являются бесплатными.',
  'tariff.txt-two': 'Вы можете выбрать Тариф необходимый для Вашей компании.',
  'tariff.units': 'объектов',
  'templateCommandTranslater.add': 'Добавление шаблона команд',
  'templateCommandTranslater.edit': 'Редактирование шаблона команд',
  'templateCommandTranslater.enterNameCommand': 'Введите название команды',
  'templateCommandTranslater.enterTextCommand': 'Введите текст команды',
  'templateCommandTranslater.nameCommand': 'Название команды',
  'templateCommandTranslater.parametrsCommand': 'Параметры команды',
  'templateCommandTranslater.selectCommand': 'ВЫБРАТЬ КОМАНДУ',
  'templateCommandTranslater.selectTypeTerminal': 'Выберите тип терминала',
  'templateCommandTranslater.textCommand': 'Текст команды',
  'templateCommandTranslater.typeTermial': 'Тип терминала',
  'text-command-terminal': 'Текст команды',
  time: 'Время',
  to: 'На',
  'tools.admin_panel_beta': 'βeta тест',
  'tools.admin_panel_v2': 'Админ. панель 2.0',
  'tracking.speed.legend.text': 'Градация скорости',
  'tracking.speed.legend.mileage': 'Пробег',
  'tracking.speed.legend.km': 'км.',
  'trailer.width': 'ширина прицепа (метр)',
  'translation.key': 'value',
  type: 'Тип',
  'units.create': 'Создание объекта',
  'units.update': 'Редактирование обьекта',
  'unitsVisiblityTranslater.filterFormMonitoring': 'Применена фильтрация из Мониторинга.',
  'unitsVisiblityTranslater.notShowAllObject': 'Отображаются не все объекты.',
  'unitsVisiblityTranslater.showAllObject': 'Показать все объекты',
  'user.create_user': 'Создание пользователя',
  'user.edit_user': 'Редактирование пользователя',
  'user.enter_language': 'Введите язык!',
  'user.history_change': 'История изменений',
  'user.invite_all': 'Пригласить во все компании',
  'user.invite_email': 'Email',
  'user.invite_role': 'Роль',
  'user.invite_role_error': 'Введите роль!',
  'user.invite_user': 'Пригласить',
  'users.approve_close_form': 'Изменения не сохранены. Вы действительно хотите закрыть профиль без сохранения?',
  'users.change_password': 'Изменить',
  'users.change_password_title': 'Изменить пароль',
  'users.close_form': 'Закрыть профиль',
  'users.confirm': 'Подтвердить',
  'users.confirm_link': 'будет отправлено сообщение с ссылкой. Перейдите по этой ссылке, чтобы подтвердить согласие на получение информационных смс от системы SKIF.PRO .',
  'users.confirm_message': 'будет отправлено письмо с просьбой подтвердить согласие на получение информационных сообщений от системы SKIF.PRO .',
  'users.confirm_notification': 'Пользователь не будет получать уведомления по e-mail до тех пор, пока не подтвердить email.',
  'users.current_password': 'Текущий пароль',
  'users.email': 'Почта',
  'users.email_placeholder': 'Введите почту',
  'users.enter': 'Вход',
  'users.exit': 'Выход',
  'users.hint.lang': 'Выберите язык',
  'users.hint.role': 'Выберите роль пользователя',
  'users.invalid_email': 'E-mail не подтверждён.',
  'users.invalid_phone': 'Телефон не подтверждён.',
  'users.invite': 'Пригласить',
  'users.invite_all': 'Пригласить во все компании',
  'users.invite_level_access': 'Уровень доступа',
  'users.isDriver': 'Водитель',
  'users.name': 'Имя',
  'users.password_empty': 'Если оставить поле пустым,Вам придет подтверждение о смене пароля на электронную почту',
  'users.sessions': 'Сессии',
  'users.subscribe': 'Пригласить пользователя',
  'users.success_email_send': 'Письмо успешно отправлено.',
  'users.success_message_send': 'Сообщение успешно отправлено.',
  'valid.text': 'Заполните поле',
  value: 'Значение',
  word: 'Word',
  'work_company.create_object': 'Создание объектов',
  'work_company.data': 'Данные от терминала',
  'work_company.data_terminal.ste_3': 'В отчете Журнал Вы также можете добавлять параметры и другие датчики. Примечание:Журнал недоступен для группы объектов',
  'work_company.data_terminal.step_1': 'Необработанные “сырые” данные от терминалов Вы можете просматривать в отчете Журнал. Для получения отчета “Журнал” необходимо перейти в модуль Отчеты',
  'work_company.data_terminal.step_2': 'Затем, в открывшемся окне необходимо выбрать объект, период и нажать на журнал в списке отчетов',
  'work_company.short_list.action_eight': 'При выборе параметров вы можете настроить их настраивать, для этого нажмите на шестеренку напротив нужного параметра, введите необходимые значения и нажмите сохранить',
  'work_company.short_list.action_five': 'Примечание.После выбора нужного типа терминала, обратите внимание на подсказку указывающую сервер и порт куда необходимо настраивать терминалы для отправки данных на сервер',
  'work_company.short_list.action_four': 'В открывшейся форме заполните все поля и выберите изображение для объекта',
  'work_company.short_list.action_nine': 'После добавления и сохранения датчика нажмите создать объект',
  'work_company.short_list.action_one': 'Для создания объектов Вам будет необходимо перейти в одну из Ваших компаний. Для этого нажмите на кнопку с именем пользователя и Компании, а затем выберите нужную компанию списке',
  'work_company.short_list.action_seven': 'Далее необходимо нажать на “+”, откроется окно добавления датчика, в котором необходимо заполнить все поля, выбрать параметры и формулы',
  'work_company.short_list.action_six': 'Для добавления датчика в объект выберите из верхнего выпадающего списка “Датчики”',
  'work_company.short_list.action_three': 'Нажмите добавить',
  'work_company.short_list.action_two': 'Для перехода в Админ-панель из любой Компании нажмите на кнопку с именем пользователя, а затем на пункт “Админ.панель”В выбранной компании перейдите в справочник объектов',
  'work_company.title': 'Рабочее место Компании',
  'terminal.blocked': 'Терминал заблокирован',
  yes: 'Да',
  'archive.title_settings': 'Настройки',
  'archive.title_events': 'События по объекту',
  'archive.title': 'Архив состояний',
  'archive.subtitle': 'Объект',
  'archive.search': 'Поиск',
  'archive.view': 'Отображение',
  'archive.add_state': 'Добавить состояние',
  'archive.state_name': 'Название состояния',
  'archive.state_protocol': 'Протокол',
  'archive.state_date': 'Активно с',
  'archive.state_imei': 'IMEI',
  'archive.current': 'Текущее',
  'archive.add_button': 'Добавить',
  'archive.zebra': 'Зебра',
  'archive.state_edit': 'Редактировать',
  'archive.state_copy': 'Копировать и сделать текущим',
  'archive.state_delete': 'Удалить',
  'archive.state_warning': 'Вы редактируете архивное состояние объекта.',
  'archive.state_save': 'Сохранить изменения',
  'archive.state_cancel': 'Отменить',
  'archives.groups_report': 'Групповой отчет',
  'archives.warning': 'Некоторые объекты имеют несколько архивных состояний за выбранный период, поэтому они не могут участвовать в формировании группового отчета и были исключены.',
  'archives.exclude': 'Исключен из группового отчета',
  'archives.requestReport': 'Перезапросить отчет',
  'trackColors.export': 'Экспортировать',
  'trackColors.limits': 'Доступно 7 градаций скорости',
  'trackColors.title': 'Цвет трека',
  'trackColors.to': 'до ',
  'trackColors.selected': 'Выбрано: ',
  'archives.object.haveState': 'Текущий объект содержит состояния',
  'request.error.504': 'Мы не смогли за приемлемое время обработать запрос. Попробуйте проверить параметры запроса.',
  'mailing.tooltip': 'Дата и время отправки первого сообщения будут рассчитаны в зависимости от выбранного периода и даты последней отправки.',
  'create.new_sensor': 'Создать первый датчик',
  'create.copy.another_sensor': 'Копировать из другого объекта',
  'copy_sensors.select.all': 'Все',
  'copy_sensors.select.name_object': 'Название объекта',
  'copy_sensors.select.protocol': 'Протокол',
  'copy_sensors.select.IMEI': 'IMEI',
  'copy_sensors.select.sim2': 'sim2',
  'copy_sensors.select.sensors': 'Датчики',
  'copy_sensors.select.groups': 'Группы',
  'linear.measurement': 'Линейное измерение',
  'unitDisplays.format.acres': 'акры',
  'unitDisplays.format.feet': 'фут',
  'unitDisplays.format.kilometers': 'км',
  'unitDisplays.format.hectares': 'га',
  'unitDisplays.format.meters': 'м',
  'unitDisplays.format.miles': 'миль',
  'unitDisplays.format.sqfeet': 'футов²',
  'unitDisplays.format.sqmeters': 'м²',
  'unitDisplays.format.sqmiles': 'миль²',
  'create.user.download.icon': 'Загрузить иконку',
  'create.user.password': '*Пароль для пользователя',
  'create.user.password.repeat': '*Пароль еще раз',
  'create.user.password.sameAs': 'Пароли должны совпадать',
  'create.user.password.rules.high': "Пароль должен содержать от 15 до 25 символов и состоять как минимум из одной заглавной английской буквы, как минимум одной строчной английской буквы и как минимум одного специального символа (~ ! @ # $ % ^ & * ( ) - = + [ '{ ] '} | ; : ' \" , < . > / ?)",
  'create.user.password.rules.middle': "Пароль должен содержать от 8 до 25 символов и состоять как минимум из одной заглавной английской буквы, как минимум одной строчной английской буквы и как минимум одного специального символа (~ ! @ # $ % ^ & * ( ) - = + [ '{ ] '} | ; : ' \" , < . > / ?)",
  'create.user.password.rules.easy': 'Пароль должен состоять от 5 до 25 символов и состоять как минимум из одной английской буквы и как минимум одной цифры',
  'create.user.not.required': 'Необязательные поля',
  'reports.table.placeholder': 'Поиск по таблицам',
  'access.settings.title': 'Настройки доступа',
  'access.settings.groups': 'Группы доступа',
  'access.settings.users': 'Пользователи (Группы)',
  'access.settings.name.role': 'Название роли:',
  'access.settings.table.name': 'ОСНОВНЫЕ',
  'access.settings.table.view': 'ПРОСМОТР',
  'access.settings.table.create.and.change': 'СОЗДАНИЕ И ИЗМЕНЕНИЕ',
  'access.settings.warning.save': 'Осторожно, вы не сохранили изменения!',
  'access.settings.basic.title': 'Общие доступы',
  'access.settings.users.count': 'Пользователей:',
  'access.settings.create.role': 'Создать роль',
  'access.settings.create.role.title': 'Название роли',
  'access.settings.create.role.specify': 'Укажите название роли',
  'access.settings.access.in.group': 'Доступы пользователей в группе',
  'access.settings.create.user': 'Добавить пользователя',
  'access.settings.create.btn': 'Добавлен',
  'access.settings.create.btn.add': 'Добавить',
  'access.settings.access.on.group': 'Доступы пользователей по группам',
  'access.settings.access.group': 'ГРУПП',
  'access.settings.access.objects': 'ОБЪЕКТОВ:',
  'access.settings.access.add.in.group': 'Добавить в группу',
  'access.modal.delete.role.title': 'Удаление роли',
  'access.modal.delete.role': 'Роль: ',
  'access.modal.delete.role.change': 'Заменяющая роль: ',
  'access.modal.delete.role.btn.cancel': 'Отменить',
  'access.modal.delete.role.btn.delete': 'Удалить роль',
  'access.modal.delete.role.description.first': 'Пользователям с ролью ',
  'access.modal.delete.role.description.second': 'будет назначена новая роль (укажите её выше).',
  'access.modal.delete.role.description.third': 'Пользователи с ролью',
  'access.settings.tooltip.change.role': 'Изменить роль на',
  'access.settings.tooltip.edit.role': 'Редактировать роль',
  'access.settings.tooltip.delete.from.group': 'Удалить из группы',
  'access.settings.tooltip.delete.user': 'Исключить пользователя',
  'access.settings.access.users.essence': 'Доступы пользователей к объектам',
  'access.settings.access.users.warning': 'Настройка доступа осуществляется по средствам добавления объекта в группу.',
  'reports.tables.fields.columns.error': 'Название колонки не может быть пустым, введите название колонки!',
  'roles.notAllowed': 'У вас не достаточно правь для этого действия',
  'monitoring.fast_tracks.custom': 'Произвольный период',
  'new.version.available': 'Доступна новая версия',
  'refresh.the.page': 'Обновите страницу для получения новых функций или ускорения работы.',
  'remind.me.later': 'Напомнить позже'
}

export default translation

